import React, { useState } from 'react';
import { Button, TextField, Typography} from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { OnboardingLayout } from './onboard-layout';

const OnboardNameScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const stateUserData = location.state?.userData || {};

  const [userData, setUserData] = useState({
    firstName: stateUserData.firstName || '',
    lastName: stateUserData.lastName || '',
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setUserData(userData => ({ ...userData, [name]: value }));
  };

  const handleNext = () => {
    navigate('/onboard/clinic-name', { state: { userData } });
  };

  const isNextButtonDisabled = userData.firstName === '' || userData.lastName === '';

  const inputChildren = (
    <>
      <Typography variant="h6">
        What's your first name?
      </Typography>
      <TextField
        name="firstName"
        value={userData.firstName}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
      <Typography variant="h6" marginTop={2}>
        What's your last name?
      </Typography>
      <TextField
        name="lastName"
        value={userData.lastName}
        onChange={handleInputChange}
        fullWidth
        margin="normal"
      />
    </>
  );

  const navChildren = (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={handleNext}
      disabled={isNextButtonDisabled}
    >
      Next
    </Button>
  );

  return (
    <OnboardingLayout
      title="Let's get started!"
      previousStepPath="/onboard/login"
      inputChildren={inputChildren}
      navChildren={navChildren}
      currentStep={1}
      totalSteps={5}
      userData={userData}
    />
  );
};

export default OnboardNameScreen;
