import React, { useEffect, useState, useContext } from 'react';
import { Box, Typography, Container, useMediaQuery, useTheme } from '@mui/material';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import DisplayHandout from './display-handout';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useHandoutData } from './handout-data-context';
import HandoutActions from './handout-actions';
import HandoutBreadcrumb from './handout-breadcrumb';
import AuthorsAndReferences from './authors-and-references';

function HandoutViewer() {
  const location = useLocation();
  const breadcrumbs = location.state?.breadcrumbs; 
  const { id: contentId } = useParams();
  const navigate = useNavigate();
  const { contentStore } = useContext(StoreContext);
  const { handoutData, setHandoutData } = useHandoutData(); 
  const theme = useTheme(); 
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [showAuthorsAndReferences, setShowAuthorsAndReferences] = useState(false);
  const [showLabels, setShowLabels] = useState(true);
  const MAX_RECENT_HANDOUTS = 5;

  const formatDate = (timestamp) => {
    if (!timestamp) return 'Date not available'; // Handle null timestamp
    const date = new Date(timestamp);
    return date.toLocaleDateString(undefined, {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  useEffect(() => {
    if (!handoutData) { 
      console.log(`Fetching content data for contentId: ${contentId}`);
      const fetchedData = contentStore.getContentFromIds([contentId]);
      if (fetchedData && fetchedData.length > 0) {
        console.log('fetchedData: ', fetchedData);
        setHandoutData(fetchedData[0]);
      } else {
        console.log('failed to fetch data');
      }
    }
    updateRecentHandouts(contentId);
  }, [contentId, contentStore, handoutData, setHandoutData]);

  const updateRecentHandouts = (newContentId) => {
    const recentHandouts = JSON.parse(localStorage.getItem('recentHandouts')) || [];
    const updatedRecentHandouts = [newContentId, ...recentHandouts.filter(id => id !== newContentId)].slice(0, MAX_RECENT_HANDOUTS);
    localStorage.setItem('recentHandouts', JSON.stringify(updatedRecentHandouts));
    console.log(`Updated recent handouts: ${updatedRecentHandouts}`);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const toggleAuthorsAndReferences = () => {
    setShowAuthorsAndReferences(!showAuthorsAndReferences);
    console.warn('change!', showAuthorsAndReferences);
  };

  if (!handoutData) {
    return (
      <Box sx={{ my: 2 }}>
        <Typography>Loading...</Typography>
      </Box>
    );
  }

  return (
    <>
      <HandoutBreadcrumb breadcrumbs={breadcrumbs} handoutTitle={handoutData.contentName}/>
      <HandoutActions handoutData={handoutData} onToggleAuthorsAndReferences={toggleAuthorsAndReferences} showLabels={showLabels}/>
      <Container component={'section'} maxWidth="md" sx={{
          width: '100%',
          overflowY: 'auto',
          backgroundColor: 'white',
          mt: isTablet ? 0 : 4
        }} className='handout-container'>
        <Box sx={{ my: 2}} component={'section'}>
          {showAuthorsAndReferences ? (
            <AuthorsAndReferences handoutData={handoutData} onToggleAuthorsAndReferences={toggleAuthorsAndReferences}/>
          ) : (
            <DisplayHandout handoutData={handoutData} showLabels={showLabels} setShowLabels={setShowLabels} />
          )}
        </Box>
      </Container>
    </>
  );
}

export default HandoutViewer;
