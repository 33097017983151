import { createTheme } from '@mui/material/styles';
import { grey, purple } from '@mui/material/colors';
import { lighten } from '@mui/system';

const customIconColor = '#FC96A2';
const primaryPink = '#FC96A2';
const secondaryPink = '#E86E7B';
const lightBlue = '#8D8DE8';
const primaryBlue = '#000453';

const getDesignTokens = (mode, fontSize) => ({
  palette: {
    background: {
      default: '#f2eff7',
    },
    mode,
    primary: {
      main: secondaryPink,
      ...(mode === 'dark' && {
        main: purple[300],
      }),
    },
    ...(mode === 'dark' && {
      background: {
        default: grey[900],
        paper: grey[800],
      },
    }),
    text: {
      ...(mode === 'light'
        ? {
          primary: grey[900],
          secondary: grey[800],
        }
        : {
          primary: '#fff',
          secondary: grey[500],
        }),
    },
    customColors: {
      iconColor: customIconColor,      
      lightPink: primaryPink,
      mediumPink: secondaryPink,
      lightBlue,
      darkBlue: primaryBlue,
    },
  },
  typography: {
    fontFamily: 'Inter, sans-serif',
    body1: {
      fontFamily: 'Inter, sans-serif',
    },
    h1: {
      fontFamily: 'Inter, sans-serif',
      color: primaryBlue,
      fontWeight: 500,
    },
    h2: {
      fontFamily: 'Inter, sans-serif',
      color: primaryBlue,
      fontWeight: 500,
    },
    h3: {
      fontFamily: 'Inter, sans-serif',
      color: primaryBlue,
      fontWeight: 500,
    },
    h4: {
      fontFamily: 'Inter, sans-serif',
      color: primaryBlue,
      fontWeight: 500,
    },
    h5: {
      fontFamily: 'Inter, sans-serif',
      color: primaryBlue,
      fontWeight: 500,
    },
    h6: {
      fontFamily: 'Inter, sans-serif',
      color: primaryBlue,
      fontWeight: 500,
    },
    documentTitle: {
      fontSize: '35pt', // Adjusted size
      fontWeight: 'bold',
      fontFamily: 'Inter, sans-serif',
      color: primaryBlue,
    },
    sectionTitle: {
      fontSize: '20pt',
      fontWeight: 'bold',
      fontFamily: 'Inter, sans-serif',
      color: primaryBlue,
      // pageBreakAfter: 'always',
    },
    sectionSubtitle: {
      fontSize: '16pt',
      fontWeight: 'bold',
      fontFamily: 'Inter, sans-serif',
      color: primaryBlue,
      // pageBreakAfter: 'always',
    }
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 12, 
          '& $notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          borderRadius: '12px',
        },
        input: {
          color: grey[500],
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          // Any global styles for all buttons can go here
          textTransform: 'none',
        },
        containedPrimary: {
          backgroundColor: primaryBlue,
          color: '#fff', // Set the button text color to white for primary color
          '&:hover': {
            backgroundColor: lighten(primaryBlue, 0.2),  // Lightens the primary button on hover
          },
        },
        containedSecondary: {
          backgroundColor: secondaryPink,  // Assuming you want mediumPink for secondary buttons
          color: '#fff', // Set the button text color to white for secondary color
          '&:hover': {
            backgroundColor: lighten(secondaryPink, 0.2),  // Lightens the secondary button on hover
          },
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          opacity: 1,
          textTransform: 'none',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          '@media print': {
            display: 'none',
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          backgroundColor: 'white', // Sets the background color of the progress bar
          borderRadius: 16
        },
        bar: {
          background: 'linear-gradient(131.61deg, #FA96C3 17.93%, #FF669B 42.82%, #BD8DE8 195.64%)'
        },
      },
    },
  },
});

export const cetraLightModeTheme = createTheme(getDesignTokens('light'));
