// consoleOverrides.js
(function () {
    const originalConsole = {
        log: console.log,
        error: console.error,
        warn: console.warn,
        info: console.info
    };

    // console.log(`consoleOverrides.js: NODE_ENV is ${process.env.NODE_ENV}`);
    if (process.env.NODE_ENV === 'production') {
        // console.log("consoleOverrides.js: NODE_ENV is production");
        console.log = () => { };
        console.info = () => { };
        console.warn = () => { };
        // Keep console.error as it is or add custom logic if needed.
    } else {
        // In development, keep all console methods as is or add custom logic if needed.
        // console.log("consoleOverrides.js: NODE_ENV is development");
        // console.log = () => { };
        // console.info = () => { };
        // console.warn = () => { };
    }

    // Optionally, you can expose a method to restore the original console methods
    console.restore = () => {
        console.log = originalConsole.log;
        console.error = originalConsole.error;
        console.warn = originalConsole.warn;
        console.info = originalConsole.info;
    };
})();
