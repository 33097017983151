import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SearchLayout } from './search-layout';
import { SearchControls } from './search-controls';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import { observer } from 'mobx-react-lite';
import { AlphabeticalSearchResults } from './alphabetical-search-results';

export const SearchScreen = observer(() => {
  const { contentStore, searchStore } = useContext(StoreContext);
  const [activeFilter, setActiveFilter] = useState('Condition');
  const [activeCategory, setActiveCategory] = useState(null);
  const [alphabeticalContent, setAlphabeticalContent] = useState({});
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const state = location.state || {};
    if (state.filter) {
      setActiveFilter(state.filter);
    }
    if (state.category) {
      setActiveCategory(state.category);
    } 
    if (state.filter === 'Condition') {
      setActiveCategory(null);
    }
  }, [location.state]);

  useEffect(() => {
    if (activeCategory) {
      // If a category is selected, load its content (optionally filtered)
      const categoryData = contentStore.getCategoriesData()[activeCategory];

      const sortedContent = contentStore.getAlphabeticallySortedContent(categoryData ? categoryData.items : null, activeFilter !== 'Condition' ? activeFilter : '');
      setAlphabeticalContent(sortedContent);
    } else {
      // No category is selected, load all content (optionally filtered)
      const sortedContent = contentStore.getAlphabeticallySortedContent(null, activeFilter !== 'Condition' ? activeFilter : '');
      setAlphabeticalContent(sortedContent);
    }
  }, [activeCategory, activeFilter, contentStore]);

  const handleFilterChange = (filterType) => {
    setActiveFilter(filterType === activeFilter ? 'Condition' : filterType);
    navigate('/search');
  };

  const handleCategoryChange = (category) => {
    setActiveCategory(category === activeCategory ? null : category);
    navigate('/search', { replace: true });
  };

  const handleSearchInputChange = (value) => {
    setActiveFilter('Condition');
    searchStore.setRawSearchTerm(value);
  };

  const searchResultsChildren = <AlphabeticalSearchResults breadcrumbs={ activeCategory ? ['SEARCH', activeCategory] : ['SEARCH']} content={alphabeticalContent} activeCategory={activeCategory} onBack={() => setActiveCategory(null)} activeFilter={activeFilter}
  onFilterChange={handleFilterChange} />;

  return (
    <SearchLayout
      inputChildren={
        <SearchControls
          searchTerm={searchStore.rawSearchTerm}
          onSearchChange={handleSearchInputChange}
          activeFilter={activeFilter}
          onFilterChange={handleFilterChange}
          activeCategory={activeCategory}
          onCategoryChange={handleCategoryChange}
        />
      }
      searchResultsChildren={searchResultsChildren}
    />
  );
});
