import React, { useState, useEffect, useContext } from 'react';
import { Box, Typography, Button, Container, Divider, AppBar, Toolbar, useMediaQuery, useTheme, Snackbar } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close'; 
import StoreContext from '../../../react-core-lib/components/app/store-context';
import HandoutPreview from '../handout/handout-preview';
import CategoryIcon from '../../UIControls/categoryIcon';
import { IconButton } from '@mui/material';
import DeleteIcon from '../../../assets/icons/Delete.svg';
import { toJS } from 'mobx';
import HandoutActions from '../handout/handout-actions';
import UserApi from '../../../rest-api/user-api';
import { useGenerateLink } from '../handout/use-generated-link';
import { Link } from 'react-router-dom';

function Handouts() {
  const { authStore, userDataStore, contentStore } = useContext(StoreContext);
  const theme = useTheme();
  const isTabletOrBelow = useMediaQuery(theme.breakpoints.down('md'));
  const [handouts, setHandouts] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarStatus, setSnackbarStatus] = useState('success');
  const { generateLink, generateLinkOnly, createLinkText, createShareData } = useGenerateLink(handouts, true);

  useEffect(() => {
    // Safely parse handouts, default to empty array if undefined or not parseable
    const handoutsData = userDataStore.userData.handouts
      ? JSON.parse(userDataStore.userData.handouts)
      : [];

    console.warn('should have saved handouts:', handoutsData)
  
    if (handoutsData.length === 0) {
      console.warn('No handouts data found, initializing with empty array.');
      userDataStore.setUserData({
        ...userDataStore.userData,
        handouts: JSON.stringify([])
      });
    }
  
    const handoutIds = handoutsData.map(handout => handout.contentId);
    console.warn('should have saved handouts IDs:', handoutIds)

    const fetchedHandouts = contentStore.getContentFromIds(handoutIds).map(handout => {
      console.warn('should have handout from content store:', handout)
      const userDataHandout = handoutsData.find(data => data.contentId === handout.contentId);
      if (userDataHandout) {
        return {
          ...handout,
          formattedCategories: userDataHandout.categories.split(',').map(category => category.trim())
        };
      }
      return handout;
    });
    setHandouts(fetchedHandouts);
  }, [userDataStore.userData.handouts, contentStore]);

  const handleShareHandouts = async () => {
    console.warn("Handouts shared!", handouts);
    try {
      const link = await generateLinkOnly();
      navigator.clipboard.writeText(link);
    } catch (error) {
      console.error('Error generating link: ', error);
    }
  };

  const handleDeleteHandout = async (handoutData) => {
    const currentHandoutsData = JSON.parse(userDataStore.userData.handouts);
    const updatedHandoutsData = currentHandoutsData.filter(handout => handout.contentId !== handoutData.contentId);
    
    let payload = {
      handouts: updatedHandoutsData 
    };

    try {
      await UserApi.updateClinicianData(authStore.idToken, payload);
      userDataStore.updateUserHandouts(updatedHandoutsData);

      console.warn('current', currentHandoutsData);
      console.warn('updated', updatedHandoutsData);

      const handoutIds = updatedHandoutsData.map(handout => handout.contentId);
      const fetchedHandouts = contentStore.getContentFromIds(handoutIds).map(handout => {
        const userDataHandout = updatedHandoutsData.find(data => data.contentId === handout.contentId);
        if (userDataHandout) {
          return {
            ...handout,
            formattedCategories: userDataHandout.categories.split(',').map(category => category.trim())
          };
        }
      return handout;
    });
      userDataStore.updateUserHandouts(updatedHandoutsData); 
      setSnackbarMessage('Handout deleted from bundle');
      setSnackbarStatus('success');
      setSnackbarOpen(true);
      setHandouts(fetchedHandouts);
    } catch (error) {
      console.error('Error adding handout to bundle: ', error);
      setSnackbarMessage('Failed to delete handout from bundle');
      setSnackbarStatus('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const getSnackbarIcon = () => {
    return snackbarStatus === 'success' ? <CheckIcon sx={{ ml: 1, color: 'white' }} /> : <CloseIcon sx={{ ml: 1, color: 'red' }} />;
  };

  return (
    <Box marginTop={4} marginBottom={12} className="handouts-container">
      <Box sx={{ backgroundColor: 'white', display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingX: 5, paddingY: 2.5, mt: 6 }}>
        <Typography variant="h4" sx={{ mb: 2 }}>
          My handouts
        </Typography>
        <Box>
          ({handouts.length})
        </Box>
      </Box>

      <Container>
        {handouts.map((handoutData, index) => (
          <React.Fragment key={handoutData.contentId}>
            <Box sx={{ display: 'flex', alignItems: 'center', flexDirection: isTabletOrBelow ? 'column' : 'row', marginBottom: 2, marginTop: 2 }}>
              <HandoutPreview handoutData={handoutData} isBundledHandout={true} />
              <Typography variant="subtitle1" sx={{ marginLeft: isTabletOrBelow ? 0 : 1, width: '100%' }}>
                {handoutData.contentName}
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                  <Box display={'flex'}>
                    {handoutData.formattedCategories.map(category => (
                      <CategoryIcon category={category} key={category} />
                    ))}
                  </Box>

                  <IconButton aria-label="delete" onClick={() => handleDeleteHandout(handoutData)} sx={{ marginRight: 1, cursor: 'pointer' }}>
                    <img src={DeleteIcon} alt='Delete handout' width={40} height={40}/>
                  </IconButton>
                </Box>
              </Typography>
            </Box>
            {index !== handouts.length - 1 && <Divider />}
          </React.Fragment>
        ))}
        {handouts.length === 0 && ( 
          <p>You have yet to save any handouts to your profile! <br/>
            <Link to="/search">Search for conditions here</Link>
          </p>
        )}
      </Container>

      {handouts.length > 0 && ( 
        <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0, backgroundColor: 'white', '@media print': { display: 'none' } }}>
          <Toolbar sx={{ display: 'flex', height: 88, alignItems: 'center' }}>
            <Typography variant="contained" color="primary" marginLeft={4} sx={{ }} width={180}>
              Share {handouts.length} handout(s)
            </Typography>
            <HandoutActions handoutData={handouts} bundledHandoutsView={true} />
          </Toolbar>
        </AppBar>
      )}
      
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={
          <Box display="flex" alignItems="center">
            {snackbarMessage}
            {getSnackbarIcon()} {/* Dynamically selected icon based on the snackbar status */}
          </Box>
        }
        anchorOrigin={{
          vertical: 'bottom', // Aligns the Snackbar at the bottom
          horizontal: 'center' // Centers the Snackbar horizontally
        }}
        sx={{
          '& .MuiSnackbarContent-root': { // Targeting the inner content root of the Snackbar
            backgroundImage: 'linear-gradient(132deg, #FA96C3 -2.07%, #FF669B 15.82%, #BD8DE8 116.64%)',
            display: 'flex', // Ensures the content root is also flex to center children
            justifyContent: 'center' // Centers the content horizontally
          }
        }}
      />
    </Box>
  );
}

export default Handouts;
