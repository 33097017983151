import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { AuthState } from '../../../stores/auth-store';
import StoreContext from './store-context';

// We are observing the authState from authStore mobx store
export const InnerApp = observer(({ AuthenticatedComponent, UnauthenticatedComponent, theme, globalComponents, globalAuthenticatedComponents }) => {
  const contextValue = useContext(StoreContext);

  console.log("Do we have contextValue?", !!contextValue);
  if (!contextValue) {
    console.log("No contextValue");
    return null;
  }

  const authStore = contextValue.authStore;

  console.log("Do we have authStore?", !!authStore);
  if (authStore) {
    console.log("Current authState:", authStore.authState);
  }

  const appToRender = authStore && authStore.authState === AuthState.AUTHENTICATED
    ? <AuthenticatedComponent />
    : <UnauthenticatedComponent />

  const globalAuthenticatedComponentsToRender = authStore && authStore.authState === AuthState.AUTHENTICATED
    ? globalAuthenticatedComponents
    : null;

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Router>
        {globalComponents}
        {globalAuthenticatedComponentsToRender}
        {appToRender}
      </Router>
    </ThemeProvider>
  );
});

function AuthenticatedAppWithStore({ AuthenticatedComponent, UnauthenticatedComponent, createAppStateStore, theme, globalComponents, globalAuthenticatedComponents }) {
  const [appStateStore, setAppStateStore] = useState(null);

  useEffect(() => {
    console.log('AuthenticatedApp::useEffect');
    setAppStateStore(createAppStateStore());
  }, []);

  if (!appStateStore) {
    return null;  // or a loading indicator
  }

  const authStore = appStateStore.authStore;

  return (
    <StoreContext.Provider value={appStateStore}>
      <InnerApp
        AuthenticatedComponent={AuthenticatedComponent}
        UnauthenticatedComponent={UnauthenticatedComponent}
        theme={theme}
        globalComponents={globalComponents}
        globalAuthenticatedComponents={globalAuthenticatedComponents}
      />
    </StoreContext.Provider>
  );
}

export default observer(AuthenticatedAppWithStore);
