import React, { useContext, useEffect } from 'react';
import { Box, Typography, useMediaQuery, FormControlLabel } from '@mui/material';
import BulletList from './bullet-list';
import Callout from './callout';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import HandoutImageManager from './handout-image-manager';
import HandoutImageLoader from './handout-image-loader';
import IconStrip from './icon-strip';
import Copyright from './copyright';
import { GlobalStyles } from '@mui/system';
import { useTheme } from '@mui/material/styles';
import { toJS } from 'mobx';
import AntSwitch from '../../UIControls/AntSwitch';

function DisplayHandout({ handoutData, showLabels, setShowLabels }) {
  const { authStore, imageStore } = useContext(StoreContext);
  const theme = useTheme(); // Access the theme
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const appBarHeight = theme.spacing(8); // Adjust the multiplier as needed
  const locale = 'en-UK';
  const localeData = handoutData.contentData[locale];
  console.warn('display-handout data ', toJS(handoutData));

  useEffect(() => {
    // Set the page title dynamically based on handout name - this will show in print PDF filename
    document.title = `Cetra - ${handoutData.contentName || 'Health Condition'}`;
  }, [handoutData.contentName]);

  if (!authStore.idToken) {
    throw new Error(`No idToken found`);
  }

  if (!localeData) {
    throw new Error(`No data found for the specified locale: ${locale}`);
  }

  return (
    <HandoutImageManager handoutData={handoutData}>
      <GlobalStyles
        styles={{
          '@media print': {
            // The content should have a bottom padding equal to or larger than the footer height to prevent content from overlapping with the footer
            '#handout-content': {
              paddingBottom: '100px', // Adjust based on the actual height of your footer
            },
          },
        }}
      />
      <Box id="handout-content" sx={{ my: 2, pb: isMobile ? appBarHeight : '0px', position: "relative" }}>
        {localeData.map((section, index) => generateSection(section, index))}
        <Copyright />
      </Box>
    </HandoutImageManager>
  );

  // top level component where each CMS section is generated
  function generateSection(jsonContent, index) {
    const sectionName = jsonContent.sectionName;
    const isPrintPageBreak = sectionName === "printPageBreak";
    const blocks = sectionName === 'diagram' 
      ? [jsonContent.blocks[showLabels ? 0 : 1]]
      : jsonContent.blocks;

    return (
      <React.Fragment key={index}>
        <Box
          component='section'
          className={`${sectionName}${isPrintPageBreak ? " printPageBreak" : ""}`}
          sx={{
            my: 4,
            '@media print': {
              pageBreakBefore: isPrintPageBreak ? 'auto' : 'avoid',
              position: 'relative',
              zIndex: 1,
            },
          }}
        >
          {sectionName === 'diagram' && (
            <FormControlLabel
              control={
                <AntSwitch
                  checked={showLabels}
                  onChange={() => setShowLabels(prev => !prev)}
                />
              }
              label="Labels"
              labelPlacement="start"
              sx={{ "position": "absolute", "top": "16px", "right": 0 ,'& .MuiFormControlLabel-label': { marginRight: '8px' } }}
            />
          )}
          {blocks.map((block, index) => (
            <React.Fragment key={index}>
              {blockToComponentMapping(block, sectionName)}
            </React.Fragment>
          ))}
        </Box>
        {isPrintPageBreak && <div></div>}
      </React.Fragment>
    );
  }

  // top level component which checks CMS block type then generates MUI/custom component for different blocks
  function blockToComponentMapping(block, sectionName) {
    switch (block.type) {
      case 'documentTitle':
        return (
          <Typography
            component="h1"
            key={block.value}
            block={block}
            variant="documentTitle"
          >
            {block.value}
          </Typography>
        );
      case 'sectionTitle':
        return (
          <Typography
            component="h2"
            key={block.value}
            variant="sectionTitle" // using the variant here
            sx={{ my: 2 }}
          >
            {block.value}
          </Typography>
        );
      case 'sectionSubtitle':
        return (
          <Typography
            component="h3"
            key={block.value}
            variant="sectionSubtitle" // using the variant here
            sx={{ my: 2 }}
          >
            {block.value}
          </Typography>
        );
      case 'calloutSmall':
        return (
          <Callout key={block.value}>
            {block.value}
          </Callout>
        );
      case 'calloutMedium':
        return (
          <Callout key={block.value}>
            {block.value}
          </Callout>
        );
      case 'paragraph':
        return <Typography key={block.value} sx={{position: 'relative',
        zIndex: 1, pb: 1,
        '@media print': {
          pageBreakInside: 'auto',
          pageBreakBefore: 'auto', 
          display: 'inline'
        } }}>{block.value}</Typography>;
      case 'list':
        return <BulletList items={block.values} />;
      case 'iconStrip':
        return (
          <IconStrip iconDetails={block.iconDetails} imageStore={imageStore} sx={{position: 'relative',
          zIndex: 1, '@media print': {
            pageBreakInside: 'avoid',
            pageBreakBefore: 'auto', 
            pb: 1,
            display: 'flex'
          } }} />
        );
      case 'illustration':
        return <HandoutImageLoader src={block.src} alt={block.alt} imageStore={imageStore} />;
      case 'printPageBreak': 
        return <>
                <div className="printPageBreak"></div><p></p>
               </>;
      default:
        return null;
    }
  }
}

export default DisplayHandout;
