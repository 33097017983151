import React, {useContext} from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Button, Typography, Checkbox, FormControlLabel, SvgIcon, Container, useMediaQuery, useTheme } from '@mui/material';
import StoreContext from '../../../react-core-lib/components/app/store-context';

// Custom circular unchecked icon
const CircleUnchecked = (
  <SvgIcon>
    <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth="2" fill="none" />
  </SvgIcon>
);

// Custom circular checked icon with checkmark
const CircleChecked = (
  <SvgIcon>
    <circle cx="12" cy="12" r="9" stroke="currentColor" strokeWidth="2" fill="currentColor" />
    <path d="M9 12l2 2 4-4" fill="none" stroke="#fff" strokeWidth="2" />
  </SvgIcon>
);

// Reusable CheckboxWithLabel component
function CheckboxWithLabel({ id, label, defaultChecked }) {
  return (
    <FormControlLabel
      control={
        <Checkbox
          id={id}
          defaultChecked={defaultChecked}
          color="primary"
          icon={CircleUnchecked}
          checkedIcon={CircleChecked}
          disabled
          sx={{
            '&.Mui-disabled': {
              color: 'primary.main', // Ensures the color remains the same
            },
            // '&.MuiCheckbox-root.Mui-disabled svg': {
            //   color: 'primary.main', // Ensures the icon color remains the same
            // },
          }}
        />
      }
      label={<Typography variant="body2">{label}</Typography>}
    />
  );
}

export function FinishProfileSetupCard() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const isBelow1200 = useMediaQuery(theme.breakpoints.down('lg'));
  const navigate = useNavigate();
  const { userDataStore } = useContext(StoreContext);

  const clinicDetails = [
    userDataStore.userData.clinicImageId,
    userDataStore.userData.clinicName,
    userDataStore.userData.clinicNumber,
    userDataStore.userData.clinicSize
  ];
  const hasMultipleClinicDetails = clinicDetails.filter(detail => detail).length >= 2;

  const handleButtonClick = () => {
    navigate('/settings');
  };

  return (
    <Container component={'section'} maxWidth={isMobile ? 'md' : 'xl'} sx={{ position: 'relative', pt: 4, px: 6 }}>
      {isBelow1200 ? (
        <Box
          bgcolor="white"
          p={2}
          borderRadius={4}
          display="flex"
          flexDirection="column"
          alignItems="start"
          gap={4}
        >
          <Typography variant="h4" align="left">
            Finish setting up your profile!
          </Typography>

          <Box display="flex" flexDirection="column" gap={2}>
            <CheckboxWithLabel
              id="1"
              defaultChecked
              label="Set up your account"
            />
            <CheckboxWithLabel
              id="2"
              label="Add your clinic details"
              defaultChecked={hasMultipleClinicDetails}
            />
            {/* need to show dynamically only if pro/clinic plan */}
            <CheckboxWithLabel
              id="3"
              label="Personalise your handouts with logos (professional and clinic plans only!"
              defaultChecked={userDataStore.userData.clinicImageId}
            />
          </Box>

          <Button
            variant="contained"
            sx={{
              backgroundColor: '#292F68',
              padding: '20px 20px',
              borderRadius: '36px',
              width: '100%',
            }}
            onClick={handleButtonClick}
          >
            Personalise
          </Button>
        </Box>
      ) : (
        <Box
          bgcolor="white"
          p={6}
          mx={5}
          borderRadius={4}
          display="flex"
          flexDirection="row"
          alignItems="start"
          gap={4}
          height={350}
        >
          <Typography variant="h3" color={'black'} align="left" sx={{ flex: '2 1 auto', fontSize:48}}>
            Finish setting up <br/> your profile!
          </Typography>

          <Box display="flex" flexDirection="column" gap={2} sx={{ flex: '1 1 auto' }}>
            <CheckboxWithLabel
              id="1"
              defaultChecked
              label="Set up your account"
            />
            <CheckboxWithLabel 
              id="2"
              label="Add your clinic details"
              defaultChecked={hasMultipleClinicDetails}
            />
            {/* need to show dynamically only if pro/clinic plan */}
            <CheckboxWithLabel
              id="3"
              label="Personalise your handouts with logos (professional and clinic plans only!)"
              defaultChecked={userDataStore.userData.clinicImageId}
            />

            <Button
              variant="contained"
              sx={{
                backgroundColor: '#292F68',
                padding: '16px',
                borderRadius: '36px',
                width: '100%',
                maxWidth: '280px',
              }}
              onClick={handleButtonClick}
            >
              Personalise
            </Button>
          </Box>
        </Box>
      )}
    </Container>
  );
}
