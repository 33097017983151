import React, { createContext, useState, useContext } from 'react';

// Create a context for the handout data
const HandoutDataContext = createContext(null);

// Create a hook to use the handout data context
export const useHandoutData = () => useContext(HandoutDataContext);

// Provider component that wraps your components that need handout data
export const HandoutDataProvider = ({ children }) => {
  const [handoutData, setHandoutData] = useState(null);

  // The value provided to the context consumers
  const contextValue = {
    handoutData,
    setHandoutData,
  };

  return (
    <HandoutDataContext.Provider value={contextValue}>
      {children}
    </HandoutDataContext.Provider>
  );
};
