import React, { useEffect, useState, useContext } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';
import { Tabs, Tab, AppBar, useMediaQuery, useTheme, Box, Badge } from '@mui/material';
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import { HomeScreen } from './home/home';
import { SearchScreen } from './search/search';
import { CategoriesSearchScreen } from './categories/categories';
import { CategoriesResults } from './categories/categories-results';
import HandoutViewer from './handout/handout-viewer';
import HandoutPDF from './handout/pdf/handout-pdf';
import { SettingsScreen } from './settings/settings';
import { SettingsDevScreen } from './settings/settings-dev';
import VerificationCodeInput from '../unauthenticated/onboard/verification-code-input';
import AuthorsAndReferences from './handout/authors-and-references';
import FeedbackScreen from './handout/feedback';
import HandoutLayout from './handout/handout-context-layout';
import StoreContext from '../../react-core-lib/components/app/store-context';
import UserApi from '../../rest-api/user-api';
import DownloadUtils from '../../utils/download-utils';
import { UnsavedChangesProvider, useUnsavedChanges } from './UnsavedChangesContext';
import cetraWhiteLogo from '../../assets/logos/cetra_white.png';
import cetraBlueLogo from '../../assets/logos/cetra_blue.png';
import searchIcon from '../../assets/icons/Search.svg';
import profilePlaceholder from '../../assets/logos/profile_placeholder_circle.svg';
import handoutIcon from '../../assets/icons/Handout.svg';
import { observer } from 'mobx-react-lite';
import Handouts from './settings/handouts';

const Navigation = observer(() => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { promptUnsavedChanges } = useUnsavedChanges();
  const location = useLocation();
  const { userDataStore } = useContext(StoreContext);
  const [handoutsLength, setHandoutsLength] = useState(0);

  const handleNavigate = (path) => {
    promptUnsavedChanges(path);
  };

  useEffect(() => {
    if (userDataStore.userData.handouts) {
      setHandoutsLength(JSON.parse(userDataStore.userData.handouts).length);
    }
  }, [userDataStore]);

  const isSettingsRoute = location.pathname === '/settings';
  const isHandoutsOrSearchRoute = location.pathname.includes('/handout') || location.pathname.includes('/search') || location.pathname.includes('/handouts') || location.pathname.includes('/feedback');
  

  return (
    <AppBar position="relative" color="default" sx={{
      top: isMobile ? 'auto' : 0,
      boxShadow: 'none',
      background: isSettingsRoute ? 'linear-gradient(132deg, #FA96C3 -2.07%, #FF669B 15.82%, #BD8DE8 116.64%)' : location.pathname.includes('/handout') ? 'white' : 'transparent',
      padding: isMobile ? '0 16px' : '0px 40px',
      position: 'relative',
      zIndex: 0,
      height: isSettingsRoute ? '130px' : isHandoutsOrSearchRoute ? '72px' : '48px',
      marginBottom: isSettingsRoute ? '-90px' : '-48px',
      bottom: isMobile ? 0 : 'auto',
      '@media print': {
        display: 'none',
      },
    }}>
      <Box display={'flex'} justifyContent={'space-between'} flexWrap={'wrap'} sx={{ opacity: 1 }}>
        <Tab 
          disableRipple 
          sx={{ p: 0 }} 
          icon={<img src={isHandoutsOrSearchRoute ? cetraBlueLogo : cetraWhiteLogo} alt="Home" style={{ p: 0 }} />} 
          onClick={() => handleNavigate('/')} 
          className={`app-logo ${isHandoutsOrSearchRoute ? 'app-logo-search' : ''}`} 
        />
        <Box position="relative">
          <Tab disableRipple icon={<img src={searchIcon} alt="Search Icon" />} onClick={() => handleNavigate('/search')} sx={{ minWidth: 'auto', padding: '12px 0px' }} />
            <Badge
              badgeContent={userDataStore?.userData?.handouts ? JSON.parse(userDataStore.userData.handouts).length : null}
              sx={{ '& .MuiBadge-badge': { backgroundColor: '#FC96A2', color: 'white', transform: 'translateZ(1px)'} }}
              overlap="circular"
              anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
              <Tab
                disableRipple
                icon={<img src={handoutIcon} alt='Handouts Icon' />}
                onClick={() => handleNavigate('/handouts')}
                sx={{ minWidth: 'auto' }}
              />
            </Badge>
          <Tab
            disableRipple
            icon={
              userDataStore.userData.clinicImageId || isMobile ?
                (
                  <img
                    width={40}
                    style={{ marginBottom: 0, marginRight: isMobile ? null : 8 }}
                    src={userDataStore.userData.clinicImageId ? userDataStore.userData.clinicImageId : isMobile ? profilePlaceholder : null}
                    alt="Clinic User Icon"
                  />
                ) : null
            }
            label={isMobile ? null : userDataStore.userData.clinicName}
            onClick={() => handleNavigate('/settings')}
            sx={{
              height: 40,
              background: '#F6F6F6',
              borderRadius: '36px',
              minHeight: 'auto',
              minWidth: isMobile ? 0 : null,
              flexDirection: 'row',
              padding: isMobile ? 0 : null
            }}
            className='header-button'
          />
        </Box>
      </Box>
    </AppBar>
  );
});

function AuthenticatedApp() {
  const { userDataStore, authStore } = useContext(StoreContext);

  useEffect(() => {
    const fetchUserAndTrackPageView = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();

        mixpanel.identify(user.attributes.email);
        
        mixpanel.people.set({
          "$first_name": userDataStore.userData.firstName,
          "$last_name": userDataStore.userData.lastName,
          "$email": userDataStore.userData.emailAddress,
          "Mobile": userDataStore.userData.mobile,
          "Clinic Name": userDataStore.userData.clinicName,
          "Clinic Number": userDataStore.userData.clinicNumber,
          "Clinic Size": userDataStore.userData.clinicSize,
        });
      } catch (error) {
        console.error('Error fetching current user:', error);
      }
    };

    const fetchClinicianData = async () => {
      if (!userDataStore.userDataState) {
        try {
          const clinicianDataResponse = await UserApi.getClinicianData(authStore.idToken);
          if (clinicianDataResponse) {
            let formattedData = {
              firstName: clinicianDataResponse.userData.firstName,
              lastName: clinicianDataResponse.userData.lastName,
              emailAddress: clinicianDataResponse.userData.emailAddress,
              mobile: clinicianDataResponse.userData.mobile || '',
              clinicName: clinicianDataResponse.clinicData.clinicName,
              clinicNumber: clinicianDataResponse.clinicData.clinicNumber || '',
              clinicSize: clinicianDataResponse.clinicData.clinicSize || '',
              clinicImageId: '',
              showLogoInHandouts: clinicianDataResponse.showLogoInHandouts,
              handouts: JSON.stringify(clinicianDataResponse.handouts)
            };
    
            if (clinicianDataResponse.clinicData.clinicImageId) {
              const payload = {
                files: [{ method: "get", key: clinicianDataResponse.clinicData.clinicImageId }],
                contentCategory: "clinic_image"
              };
              try {
                const clinicImageURL = await UserApi.generatedPresignedUrls(authStore.idToken, payload);
                if (clinicImageURL && clinicImageURL.getUrls && clinicImageURL.getUrls.length > 0) {
                  let clinicImageBase64 = await DownloadUtils.downloadImageFromPresignedUrl(clinicImageURL.getUrls[0].presignedUrl);
                  formattedData.clinicImageId = clinicImageBase64.base64data;
                }
              } catch (presignError) {
                console.error("Error generating presigned URL:", presignError);
              }
            }
            userDataStore.setUserData(formattedData);
          }
        } catch (error) {
          console.error("Failed to fetch clinician data:", error);
        }
      }
    };

    fetchClinicianData();
    fetchUserAndTrackPageView();
  }, []);

  return (
    <UnsavedChangesProvider>
      <main id="main-content" style={{ zoom: document.documentElement.style.zoom || 1 }}>
        <Navigation />
        <Routes>
          <Route path="/" element={<HomeScreen />} />
          <Route path="/search" element={<SearchScreen />} />
          <Route path="/search/categories" element={<CategoriesSearchScreen />} />
          <Route path="/search/diagrams" element={<SearchScreen />} />
          <Route path="/categories-results" element={<CategoriesResults />} />
          <Route path="/settings" element={<SettingsScreen />} />
          <Route path="/settings/dev" element={<SettingsDevScreen />} />
          <Route path="/feedback" element={<FeedbackScreen />} />
          <Route path="/handout/:id" element={<HandoutLayout />}>
            <Route index element={<HandoutViewer />} />
            <Route path="pdf" element={<HandoutPDF />} />          
            <Route path="authors-and-references" element={<AuthorsAndReferences />} />          
          </Route>
          <Route path="/code-input" element={<VerificationCodeInput />} />
          <Route path="/handouts" element={<Handouts />} />
        </Routes>
      </main>
    </UnsavedChangesProvider>
  );
}

export default AuthenticatedApp;
