import React from 'react';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';

const Callout = ({ children }) => {
  const theme = useTheme();

  return (
    <Paper
      elevation={0}
      sx={{
        borderRadius: '16px',
        p: 3,
        m: 3,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        boxShadow: `4px 4px 0px -1px ${theme.palette.customColors.darkBlue}`, // Negative spread radius to show border
        border: `1px solid ${theme.palette.customColors.darkBlue}`, // Add border all around
        overflow: 'hidden',
        mb: 4,
        '@media print': {
          pageBreakInside: 'avoid',
          pageBreakBefore: 'auto', 
        }
      }}
    >
      <Typography color={theme.palette.text.primary}>
        {children}
      </Typography>
    </Paper>
  );
};

export default Callout;


