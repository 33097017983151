const KLAVIYO_API_KEY = process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ?'SmNQzf' : 'USbu7j';
const KLAVIYO_EVENTS_ENDPOINT = 'https://a.klaviyo.com/client/events/';
const KLAVIYO_SUBSCRIBE_ENDPOINT = 'https://a.klaviyo.com/client/subscriptions/';
const LIST_ID = process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 'QZHfuk' : 'X8Qcap'

const sendToKlaviyo = async (endpoint, data) => {
  const response = await fetch(`${endpoint}?company_id=${KLAVIYO_API_KEY}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'REVISION': '2024-02-15' 
    },
    body: JSON.stringify(data)
  });

  if (!response.ok) {
    throw new Error(`Klaviyo request failed: ${response.statusText}`);
  }

  return response.json();
};

const subscribeUser = async (userData) => {
  const subscriptionData = {
    data: {
      type: 'subscription',
      attributes: {
        profile: {
          data: {
            type: 'profile',
            attributes: {
              email: userData.emailAddress,
              first_name: userData.firstName,
              last_name: userData.lastName,
              organization: userData.clinicName
            }
          }
        },
      },
      relationships: {
        list: {
          data: { type: 'list', id: LIST_ID }
        }
      }
    }
  };
  
  return sendToKlaviyo(KLAVIYO_SUBSCRIBE_ENDPOINT, subscriptionData);
};

const trackEvent = async (eventName, email) => {
  const eventData = {
    data: {
      type: 'event',
      attributes: {
        metric: {
          data: {
            type: 'metric',
            attributes: {
              name: eventName
            }
          }
        },
        profile: {
          data: {
            type: 'profile',
            attributes: {
              email: email
            }
          }
        },
        properties: {
          email: email
        }
      }
    }
  };

  return sendToKlaviyo(KLAVIYO_EVENTS_ENDPOINT, eventData);
};

export default { trackEvent, subscribeUser };
