import React from 'react';
import { Box, Button } from '@mui/material';
import { observer } from 'mobx-react-lite';
import bodyAreaIcon from '../../assets/icons/categories/Body-Area.svg';
import generalAnatomy from '../../assets/icons/categories/General-anatomy.svg';
import generalAnatomyAnimal from '../../assets/icons/categories/General-anatomy-animal.svg';
import generalPrinciples from '../../assets/icons/categories/General-principles.svg';
import generalPrinciplesAnimal from '../../assets/icons/categories/General-principles-animal.svg';
import neurologyIcon from '../../assets/icons/categories/Neurology.svg';
import womensHealthIcon from '../../assets/icons/categories/Womens-health.svg';
import behaviourIcon from '../../assets/icons/categories/Behaviour.svg';
import emergencyIcon from '../../assets/icons/categories/Emergency.svg';
import farmIcon from '../../assets/icons/categories/Farm.svg';
import musculoskeletalIcon from '../../assets/icons/categories/Musculoskeletal.svg';
import preventativeIcon from '../../assets/icons/categories/Preventative.svg';
import parasitesIcon from '../../assets/icons/categories/Parasites.svg';
import reproductionIcon from '../../assets/icons/categories/Reproduction.svg';
import surgeryIcon from '../../assets/icons/categories/Surgery.svg';
import animalIcon from '../../assets/icons/categories/Animal.svg';
import denistryIcon from '../../assets/icons/categories/Denistry.svg';
import dermatologyIcon from '../../assets/icons/categories/Dermatology.svg';
import orthopaedicIcon from '../../assets/icons/categories/Orthopaedic.svg';
import ophthalmologyIcon from '../../assets/icons/categories/Ophthalmology.svg';
import internalMedicineIcon from '../../assets/icons/categories/Internal-medicine.svg';
import birdIcon from '../../assets/icons/categories/Bird.svg';
import canineIcon from '../../assets/icons/categories/Canine.svg';
import felineIcon from '../../assets/icons/categories/Feline.svg';
import fishIcon from '../../assets/icons/categories/Fish.svg';

import equineIcon from '../../assets/icons/categories/Equine.svg';
import guineaPigIcon from '../../assets/icons/categories/Guinea-pig.svg';
import hamsterIcon from '../../assets/icons/categories/Hamster.svg';
import rabbitIcon from '../../assets/icons/categories/Rabbit.svg';
import smallAnimalsIcon from '../../assets/icons/categories/Small-animals.svg';
import smallMammalsIcon from '../../assets/icons/categories/Small-mammals.svg';

import largeAnimalsIcon from '../../assets/icons/categories/Large-animals.svg';
import exoticAnimalsIcon from '../../assets/icons/categories/Exotic-animals.svg';
import { useNavigate } from 'react-router-dom';
import { CATEGORIES, CATEGORIES_VET } from '../../stores/categories';

const categoryIconMap = {
  'Bird': birdIcon,
  'Canine': canineIcon,
  'Fish': fishIcon,
  'Feline': felineIcon,
  'Equine': equineIcon,
  'Guinea pig': guineaPigIcon ,
  'Hamster': hamsterIcon,
  'Rabbit': rabbitIcon,
  'Small mammals': smallMammalsIcon,
  'Small Animals': smallAnimalsIcon,
  'Large Animals': largeAnimalsIcon,
  'Exotic Animals': exoticAnimalsIcon,
  'Dentistry': denistryIcon,
  'Dermatology': dermatologyIcon,
  'General anatomy': process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? generalAnatomyAnimal : generalAnatomy,
  'General principles': process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? generalPrinciplesAnimal : generalPrinciples,
  "Women's health": womensHealthIcon,
  'Neurology': neurologyIcon,
  'Behaviour': behaviourIcon,
  'Emergency': emergencyIcon,
  'Farm': farmIcon,
  'Internal medicine': internalMedicineIcon,
  'Musculoskeletal': musculoskeletalIcon,
  'Orthopaedics': orthopaedicIcon,
  'Ophthalmology': ophthalmologyIcon,
  'Preventative': preventativeIcon,
  'Parasites': parasitesIcon,
  'Reproduction': reproductionIcon,
  'Surgery': surgeryIcon,
};

const CategoryIcon = observer(({ category }) => {
  const navigate = useNavigate();

  const getCategoryIcon = (category) => {
    return categoryIconMap[category] || (process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? animalIcon : bodyAreaIcon);
  };
  

  const handleCategoryClick = () => {
    if (isBodyAreaCategory(category) || isAnimalCategory(category) || category === 'Animal' || category === 'Body Area') {
      navigate('/search/categories');
    } else {
      navigate('/search', { state: { category } });
    }
  };

  const isAnimalCategory = (category) => {
    return (
      CATEGORIES_VET["Animal"].some((animalCategory) => {
        if (animalCategory.name === category) {
          return true;
        }
        return animalCategory.subcategories.includes(category);
      })
    );
  };

  const isBodyAreaCategory = (category) => {
    return (
      CATEGORIES["Body Area"].some((bodyAreaCategory) => {
        if (bodyAreaCategory.name === category) {
          return true;
        }
        return bodyAreaCategory.subcategories.includes(category);
      })
    );
  };

  return (
    <Box onClick={handleCategoryClick} sx={{
        width: '40px',
        height: '40px',
        gap: '8px',
        borderRadius: '36px',
        opacity: '0px',
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginRight: 1,
        cursor: 'pointer'
      }}>
      <img
        src={getCategoryIcon(category)}
        alt={`${category} Category Icon`}
        width="24px"
        height="24px"
      />
    </Box>
  );
});

export default CategoryIcon;
