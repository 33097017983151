import { makeObservable, action, observable, computed } from 'mobx';

class CategoryStore {
  expandedCategories = {};
  expandedSubcategories = {};

  constructor() {
    makeObservable(this, {
      expandedCategories: observable,
      expandedSubcategories: observable,
      toggleCategoryExpand: action.bound,
      toggleSubcategoryExpand: action.bound,
      isSubcategoryExpanded: computed
    });
  }

  toggleCategoryExpand(categoryName) {
    this.expandedCategories[categoryName] = !this.expandedCategories[categoryName];
  }

  /**
   * Toggles the expansion state of a subcategory given its path.
   * The path is an array representing the hierarchy from category to the deepest subcategory.
   * Example path: ["Exotic Animals", "Reptiles", "Lizard"]
   */
  toggleSubcategoryExpand(path) {
    const pathString = path.join('.');
    this.expandedSubcategories[pathString] = !this.expandedSubcategories[pathString];
  }

  /**
   * Checks if a subcategory is expanded by its path.
   */
  get isSubcategoryExpanded() {
    return (path) => {
      const pathString = path.join('.');
      return !!this.expandedSubcategories[pathString];
    };
  }
}

const createCategoryStore = async (userId) => {
  const store = new CategoryStore();
  return store; 
};

export default createCategoryStore;
