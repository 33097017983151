import { useContext } from 'react';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import UserApi from '../../../rest-api/user-api';

export function useGenerateLink(handoutData, isBundled = false) {
  const { authStore, userDataStore } = useContext(StoreContext);

  // Process handout data to ensure it is in the correct format for the API call
  const processHandoutData = () => isBundled ? handoutData.map(data => data.contentId) : [handoutData.contentId];

  const generateLink = async () => {
    const contentIds = processHandoutData();
    console.warn('Content IDs to process:', contentIds);

    try {
      const result = await UserApi.generatePatientLink(authStore.idToken, contentIds);
      const userId = userDataStore.userId;
      const linkWithClinicId = `${result.linkId}&clinicId=${userId}`;

      // Cache the link generation details
      const dataToCache = {
        linkId: result.linkId,
        linkWithClinicId,
        timestamp: new Date().getTime()
      };
      localStorage.setItem('contentLink', JSON.stringify(dataToCache));

      return linkWithClinicId;  // Return the single linkId with clinicId
    } catch (error) {
      console.error('Error generating link:', error);
      throw new Error('Failed to generate link');
    }
  };

  const generateLinkOnly = async () => {
    const linkWithClinicId = await generateLink();
    return `${process.env.REACT_APP_PATIENT_HANDOUT_URL}?linkId=${linkWithClinicId}`;
  };

  const createLinkText = async () => {
    const link = await generateLinkOnly();
    const body = `Your information sheet is ready! Please click on the link below to view it:\n\n${link}`;
    return body;
  };

  const createShareData = async () => {
    const link = await generateLinkOnly();
    const title = `Cetra ${process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 'Vets' : 'Physio'} Information Sheet(s)`;
    const message = `Please click on the link below to view your information sheet(s):\n\n${link}`;
    return { title, url: link, message };
  };

  return { generateLink, generateLinkOnly, createLinkText, createShareData };
}
