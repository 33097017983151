import React, { useContext } from 'react';
import { Box, Typography, useMediaQuery, useTheme, Card, CardActionArea } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import HandoutImageLoader from './handout-image-loader';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import { toJS } from 'mobx';

function HandoutPreview({ handoutData, isBundledHandout = false, categories }) {
  const { imageStore } = useContext(StoreContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const locale = 'en-UK';
  const localeData = handoutData.contentData[locale] || [];
  const introductionSection = localeData.find(section => section.sectionName === "introduction" || section.sectionName === "diagram") || { blocks: [] };
  
  // Find the first paragraph and illustration in the introduction section
  const firstParagraph = introductionSection.blocks.find(block => block.type === 'paragraph') || {};
  const firstIllustration = introductionSection.blocks.find(block => block.type === 'illustration');

  // Log the first section, paragraph, and illustration
  // console.log(`localeData json: ${JSON.stringify(localeData)}`);
  // console.log('introductionSection:', introductionSection);
  // console.log('First Paragraph:', firstParagraph);
  // console.warn('First Illustration in handout-preview.js:', toJS(firstIllustration));

  const handlePreviewClick = async () => {
    const currentUser = await Auth.currentAuthenticatedUser();
    mixpanel.track('HandoutOpened', {
      email: currentUser.attributes.email,
      handoutTitle: handoutData.contentName
    });
    
    navigate(`/handout/${handoutData.contentId}`);
  };

  return (
    <Card
      sx={{
        width: isMobile ? '100%' : theme.spacing(35),
        minHeight: theme.spacing(20),
        boxShadow: 3,
        margin: isMobile ? theme.spacing(0) : theme.spacing(1),
        marginBottom: theme.spacing(1),
        borderRadius: theme.shape.borderRadius,
      }}
      className='handout-preview-card'
    >
      <CardActionArea onClick={handlePreviewClick}>
        <Box
          sx={{
            padding: theme.spacing(2),
            border: '0px',
            background: theme.palette.background.paper,
            height: isMobile ? '370px': '320px'
          }}
        >
          <Typography variant="h6" gutterBottom>
            {handoutData.contentName}
          </Typography>
          <Typography variant="body2" gutterBottom>
            {firstParagraph.value?.substring(0, 100)} 
            {firstParagraph.value ? '...' : ''}
          </Typography>
          {firstIllustration && (
            <HandoutImageLoader
              src={firstIllustration.src}
              alt={firstIllustration.alt}
              imageStore={imageStore}
              handoutData={handoutData}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          )}
        </Box>
      </CardActionArea>
    </Card>
  );
}

export default HandoutPreview;
