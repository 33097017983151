import { Amplify, Auth } from 'aws-amplify';

const configureAmplify = () => {
  const authConfig = {
    region: 'eu-west-2',
    userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  };

  if (process.env.REACT_APP_AWS_COGNITO_ENDPOINT) {
    authConfig.endpoint = process.env.REACT_APP_AWS_COGNITO_ENDPOINT;
  }

  Amplify.configure({
    Auth: authConfig,
  });

  // Initialize Amplify
  Auth.configure(authConfig);
};

export default configureAmplify;
