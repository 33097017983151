import React, { useState, useEffect, useContext } from 'react';
import { AppBar, Toolbar, useMediaQuery, useTheme, Box, Snackbar, Tooltip } from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close'; 
import CustomToolbarButton from './toolbar/custom-toolbar-button';
import ASCopyIcon from '../../../assets/icons/Copy.svg';
import ASPrintIcon from '../../../assets/icons/Print.svg';
import ASReferencesIcon from '../../../assets/icons/References.svg';
import ApiIconButton from '../../UIControls/api-button/api-icon-button';
import ShareButton from './share-button';
import HandoutEmailButton from './handout-email-button';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import { useGenerateLink } from './use-generated-link';
import buttonPlusIcon from '../../../assets/icons/Button_plus.svg';
import { toJS } from 'mobx';
import UserApi from '../../../rest-api/user-api';
import StoreContext from '../../../react-core-lib/components/app/store-context';

function HandoutActions({ handoutData, onToggleAuthorsAndReferences , bundledHandoutsView = false, showLabels}) {
  const { authStore, userDataStore } = useContext(StoreContext);
  const { generateLink, generateLinkOnly, createLinkText, createShareData } = useGenerateLink(handoutData, bundledHandoutsView);
  const navigate = useNavigate();
  const theme = useTheme();
  const [currentUser, setCurrentUser] = useState(null);
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarStatus, setSnackbarStatus] = useState('success');

  useEffect(() => {
    const fetchCurrentUser = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setCurrentUser(user);
      } catch (error) {
        console.error('Error fetching current user: ', error);
      }
    };
    fetchCurrentUser();
  }, []);

  const handleCopyLink = async () => {
    try {
      const link = await generateLinkOnly();
      navigator.clipboard.writeText(link);
      trackHandoutShared('Copy handout');
    } catch (error) {
      console.error('Error generating link: ', error);
    }
  };

  const handlePrint = () => {
    trackHandoutShared('Print Handout');
    handleViewHandoutPDF();
  };

  const handleViewHandoutPDF = () => {
    trackHandoutShared('View Handout PDF');
    const url = new URL(window.location.origin + `/handout/${handoutData.contentId}/pdf`);
    url.searchParams.append('showLabels', showLabels);
    window.open(url.toString(), '_blank');
  };
  
  const trackHandoutShared = (shareMethod) => {
    mixpanel.track('HandoutShared', {
      email: currentUser?.attributes?.email,
      shareMethod: shareMethod,
      handoutTitle: handoutData.contentName
    });
  };

  const flattenCategories = (categories) => {
    let result = [];

    function traverse(currentObject) {
        if (typeof currentObject === 'string') {
            result.push(currentObject);
        } else if (Array.isArray(currentObject)) {
            currentObject.forEach(item => {
                if (typeof item === 'object') {
                    traverse(item); // Recursively process each item in the array
                } else {
                    result.push(item);
                }
            });
        } else {
            for (const key in currentObject) {
                result.push(key); 
                traverse(currentObject[key]); // Recursively process the value
            }
        }
    }

    traverse(categories);
    return result.join(', ');
  }

  const handleAddToBundledHandouts = async () => {
    console.warn(toJS(handoutData));
    console.warn(toJS(userDataStore));

    // Handle case where handouts may be undefined
    const userHandoutsJson = userDataStore.userData.handouts || '[]';
    let userHandouts = JSON.parse(userHandoutsJson);
    let formattedCategories = flattenCategories(handoutData.categories);
    let existingContentIds = userHandouts.map(handout => handout.contentId);

    // Check if contentId already exists to prevent duplicates
    if (existingContentIds.includes(handoutData.contentId)) {
      setSnackbarMessage('Handout already exists in the bundle');
      setSnackbarStatus('error');
      setSnackbarOpen(true);
      return; 
    }

    if (userHandouts.length >= 5) {
      setSnackbarMessage('Cannot add more than 5 handouts to the bundle');
      setSnackbarStatus('error');
      setSnackbarOpen(true);
      return; 
    }

    // Add new handout to the list
    userHandouts.push({
      "contentId": handoutData.contentId,
      "contentName": handoutData.contentName,
      "categories": formattedCategories
    });

    let payload = {
      handouts: userHandouts
    };

    console.warn(payload);

    try {
      await UserApi.updateClinicianData(authStore.idToken, payload);
      // After successful API call, update the local user handouts
      userDataStore.updateUserHandouts(userHandouts);
      setSnackbarMessage('Handout added to bundle');
      setSnackbarStatus('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Error adding handout to bundle: ', error);
      setSnackbarMessage('Failed to add handout to bundle');
      setSnackbarStatus('error');
      setSnackbarOpen(true);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbarOpen(false);
  };

  const getSnackbarIcon = () => {
    return snackbarStatus === 'success' ? <CheckIcon sx={{ ml: 1, color: 'white' }} /> : <CloseIcon sx={{ ml: 1, color: 'red' }} />;
  };

  return (
    <>
      {!isTablet && (
        <Box
          sx={{
            width: '100%',
            padding: bundledHandoutsView ? '0' : '0 40px',
            height: 88,
            whiteSpace: 'nowrap',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            '@media print': {
              display: 'none',
            },
            backgroundColor: 'white'
          }}
          className='handout-actions-container'
        >
          {!bundledHandoutsView && (
            <Box sx={{ cursor: 'pointer' }}> 
            <img onClick={handleAddToBundledHandouts} src={buttonPlusIcon} alt='Add to bundled handout button' />
            </Box>
          )}
          
          <Box>
            {!bundledHandoutsView && (
              // <Tooltip title="Print - Unavailable during Beta" arrow>
              //   <Box sx={{ display: 'inline-block'}}>
              //   <CustomToolbarButton
              //     iconSrc={ASPrintIcon}
              //     tooltipTitle="Print"
              //     onClick={handlePrint}
              //     disabled={true}
              //     sx={{
              //       cursor: 'not-allowed',
              //       opacity: 0.3,
              //     }}
              //   />
              //   </Box>
              // </Tooltip>

              <Tooltip title="Print" arrow>
              <Box sx={{ display: 'inline-block'}}>
              <CustomToolbarButton
                iconSrc={ASPrintIcon}
                tooltipTitle="Print"
                onClick={handlePrint}
              />
              </Box>
              </Tooltip>
            )}
            <ApiIconButton
              apiCall={handleCopyLink}
              icon={<CustomToolbarButton iconSrc={ASCopyIcon} tooltipTitle={bundledHandoutsView ? "Copy handouts" : "Copy handout"} />}
            />
            <HandoutEmailButton handoutData={handoutData} bundledHandoutsView={bundledHandoutsView} currentUser={currentUser} />
            <ShareButton generateLink={createShareData} bundledHandoutsView={bundledHandoutsView} currentUser={currentUser} />
            {!bundledHandoutsView && (
              <CustomToolbarButton iconSrc={ASReferencesIcon} tooltipTitle="Authors and references" onClick={onToggleAuthorsAndReferences} />
            )}
          </Box>
        </Box>
      )}
      {isTablet && (
        <AppBar position="fixed" color="default" sx={{ top: 'auto', bottom: 0, backgroundColor: 'white', pr: 7, '@media print': { display: 'none' } }}>
          <Toolbar sx={{ display: 'flex', justifyContent: 'space-between', height: 88 }}>
            {!bundledHandoutsView && (
              <Box sx={{ cursor: 'pointer' }}> 
                <img onClick={handleAddToBundledHandouts} src={buttonPlusIcon} alt='Add to bundled handout button' />
              </Box>
            )}            
            <Box>
              <ShareButton generateLink={createShareData} currentUser={currentUser} />
              {!bundledHandoutsView && (
                <>
                  <Tooltip title="Print - Unavailable during Beta" arrow>
                    <Box sx={{ display: 'inline-block' }}>
                    <CustomToolbarButton
                      iconSrc={ASPrintIcon}
                      tooltipTitle="Print"
                      onClick={handlePrint}
                      disabled={true}
                      sx={{
                        cursor: 'not-allowed',
                        opacity: 0.3,
                      }}
                    />
                    </Box>
                  </Tooltip>
                  <CustomToolbarButton iconSrc={ASReferencesIcon} tooltipTitle="Authors and references" onClick={onToggleAuthorsAndReferences} />
                </>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      )}

       <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={
          <Box display="flex" alignItems="center">
            {snackbarMessage}
            {getSnackbarIcon()}
          </Box>
        }
        anchorOrigin={{
          vertical: 'bottom', // Aligns the Snackbar at the bottom
          horizontal: 'center' // Centers the Snackbar horizontally
        }}
        sx={{
          '& .MuiSnackbarContent-root': { // Targeting the inner content root of the Snackbar
            backgroundImage: 'linear-gradient(132deg, #FA96C3 -2.07%, #FF669B 15.82%, #BD8DE8 116.64%)',
            display: 'flex', // Ensures the content root is also flex to center children
            justifyContent: 'center' // Centers the content horizontally
          }
        }}
      />
    </>
  );
}

export default HandoutActions;
