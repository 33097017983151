import React, { useContext, useState, useMemo  } from 'react';
import { Button, TextField, Container, useMediaQuery, Box, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Drawer, FormHelperText, List, ListItem, ListItemIcon, ListItemText} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import { useNavigate } from 'react-router-dom';
import cetraLogo from '../../../assets/logos/cetra-01-optimized.svg';
import cetraBlueLogo from '../../../assets/logos/cetra_blue.png';
import cetraWhiteLogo from '../../../assets/logos/cetra_white.png';
import ApiButton from '../../UIControls/api-button/api-button';
import PinkGradientButton from '../../UIControls/pink-gradient-button';
import { Auth } from 'aws-amplify';

const OnboardLoginResetPasswordScreen = () => {
  const appStateStore = useContext(StoreContext);
  const { authStore } = useContext(StoreContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');  
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [resetStage, setResetStage] = useState('request');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [error, setError] = useState('');

  const passwordRequirements = [
    { test: pwd => pwd.length >= 8, text: "At least 8 characters" },
    { test: pwd => /[A-Z]/.test(pwd), text: "An uppercase letter" },
    { test: pwd => /[a-z]/.test(pwd), text: "A lowercase letter" },
    { test: pwd => /\d/.test(pwd), text: "A number" }
  ];

  const passwordCriteria = useMemo(() => passwordRequirements.map(req => ({
    ...req,
    meets: req.test(newPassword)
  })), [newPassword]);

  const isPasswordValid = useMemo(() => passwordCriteria.every(criterion => criterion.meets), [passwordCriteria]);

  const canSubmit = useMemo(() => isPasswordValid, [isPasswordValid]);

  const handleForgotPassword = async () => {
    if (!email) {
      alert('Please enter your email address.');
      return;
    }
    try {
      await Auth.forgotPassword(email);
      setResetStage('reset');
      setIsDialogOpen(false);
      console.log('Verification code sent to email.');
    } catch (error) {
      console.error('Forgot password error:', error);
      if (error.code === 'UserNotFoundException') {
        setErrorMessage('No account found with the provided email address.');
      } else {
        setErrorMessage('Failed to send verification code.');
      }
      setIsDialogOpen(true);
    }
  };
  
  const handleResetPassword = async () => {
    if (!canSubmit) {
      setError('Your new password does not meet all requirements.');
      return;
    }

    try {
      await Auth.forgotPasswordSubmit(email, verificationCode, newPassword);
      alert('Password reset successfully! Please login with your new password');
      navigate('/onboard/login'); 
    } catch (error) {
      console.error('Error resetting password:', error);
      if (error.code === 'CodeMismatchException') {
        setErrorMessage('The verification code entered is incorrect. Please try again.');
      } else if (error.code === 'ExpiredCodeException') {
        setErrorMessage('The verification code has expired. Please request a new one.');
      } else if (error.code === 'InvalidPasswordException') {
        setErrorMessage('Password does not conform to policy: Password must have uppercase characters');
      } else {
        setErrorMessage('Failed to reset password.');
      }
      setIsDialogOpen(true);
    }
  };
  
  const sidebar = (
    <Drawer
      variant="permanent"
      sx={{
        width: 300,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 300, boxSizing: 'border-box', background: 'linear-gradient(132deg, rgb(250, 150, 195) -2.07%, rgb(255, 102, 155) 15.82%, rgb(189, 141, 232) 116.64%)' },
        display: { xs: 'none', md: 'block' }
      }}
    >
      <Box sx={{ paddingTop: 2 }}>
        <img src={cetraWhiteLogo} alt='Cetra Logo' style={{marginLeft:'16px'}}/>
        <Typography variant="h6" align="left" marginLeft={2} marginTop={1} marginBottom={5} color={'white'}>{process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 
        'Empowering pet owners through education.' : 'Extending patient education from clinic to home.'}</Typography>
      </Box>
    </Drawer>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      {sidebar}
      <Container component={'section'} sx={{ minHeight: 500, height: '90vh', position: 'relative' }}>
        <Box sx={{
          display: 'flex',
          justifyContent: 'center', 
          alignItems: 'center', 
          mb: 8, 
          height: 42
        }}>
          {isMobile && (
          <img
            src={cetraBlueLogo}
            alt="Cetra Logo"
            style={{
              maxWidth: '100%', 
              objectFit: 'contain', 
              paddingTop: '16px'
            }}
          />
        )}
        </Box>
        {resetStage === 'request' && (
          <>
            <Typography>
              Please enter your email - we will send a verification code to reset your account's password
            </Typography>
            <TextField
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button
              color="primary"
              onClick={handleForgotPassword}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent' 
                }
              }}
            >
              Send Reset Code
            </Button>
          </>
        )}
  
        {resetStage === 'reset' && (
          <>
            <Typography>
              Please enter the verification code we have sent to your email - remember to check junk/spam if needed. Then enter your new password
            </Typography>
            <TextField
              label="Verification Code"
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
              fullWidth
              margin="normal"
            />
            <TextField
              label="New Password"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              fullWidth
              margin="normal"
            />
            <Button
              color="primary"
              onClick={handleResetPassword}
              sx={{
                '&:hover': {
                  backgroundColor: 'transparent' 
                }
              }}
            >
              Reset Password
            </Button>
            <List dense>
              {passwordCriteria.map((criterion, index) => (
                <ListItem key={index} disablePadding>
                  <ListItemIcon>
                    {criterion.meets ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
                  </ListItemIcon>
                  <ListItemText primary={criterion.text} />
                </ListItem>
              ))}
            </List>
            {error && <FormHelperText error>{error}</FormHelperText>}
          </>
        )}
  
        <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
          <DialogTitle>{"Error"}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {errorMessage}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setIsDialogOpen(false)} color="primary" autoFocus>
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Box>
  );
  
};

export default OnboardLoginResetPasswordScreen;
