import React from 'react';
import { ImageList, ImageListItem, Typography, useMediaQuery, useTheme, Box } from '@mui/material';
import HandoutImageLoader from './handout-image-loader';

function IconStripDesktop({ iconDetails, imageStore }) {
  return (
    <ImageList cols={iconDetails.length} rowHeight="auto" gap={4}>
      {iconDetails.map((icon) => (
        <ImageListItem key={icon.img}>
          <Box display="flex" justifyContent="center" width="100%">
            <HandoutImageLoader 
              src={icon.img} 
              alt={icon.alt} 
              imageStore={imageStore}
              style={{ width: '100%', height: 'auto' }} 
            />
          </Box>
          <Typography textAlign="center" mt={1}>{icon.caption}</Typography>
        </ImageListItem>
      ))}
    </ImageList>
  );
}

function IconStripMobileTablet({ iconDetails, imageStore }) {
  // Assuming '160px' is a good height for the icons on tablets
  const iconHeight = '160px';

  return (
    <ImageList cols={1} rowHeight='auto' gap={8}> {/* Increased gap for better spacing */}
      {iconDetails.map((icon, index) => (
        <ImageListItem key={icon.img} style={{ height: 'auto', marginBottom: 24 }}> {/* Added marginBottom for spacing */}
          <Box display="flex" justifyContent="center" alignItems="center" width="100%" height={iconHeight}>
            <HandoutImageLoader
              src={icon.img}
              alt={icon.alt}
              imageStore={imageStore}
              style={{ width: '100%', maxWidth: '140px', height: 'auto', objectFit: 'contain' }}
            />
          </Box>
          {/* Added margin-top to the Typography to ensure spacing from the previous icon */}
          <Typography textAlign="center" mt={2}>
            {icon.caption}
          </Typography>
        </ImageListItem>
      ))}
    </ImageList>
  );
}

function IconStrip({ iconDetails, imageStore, bearerToken }) {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('sm')); // Adjust this breakpoint as needed for your design

  return isMobileOrTablet 
    ? <IconStripMobileTablet iconDetails={iconDetails} imageStore={imageStore} />
    : <IconStripDesktop iconDetails={iconDetails} imageStore={imageStore} />;
}


export default IconStrip;