import React, { useEffect } from 'react';
import AuthenticatedApp from './components/authenticated/authenticated';
import UnauthenticatedApp from './components/unauthenticated/unauthenticated-app';
import createAppStateStore from './app-state-store';
import { cetraLightModeTheme } from './theme';
import { observer } from 'mobx-react-lite';
import AppAuthStateObserver from './components/AuthStateObserver/AuthStateObserver';
import ContentManager from './components/state-mgmt/content-manager';
import AuthenticatedAppWithStore from './react-core-lib/components/app/authenticated-app-with-store';
import configureAmplify from './react-core-lib/third-party/amplify';
import mixpanel from 'mixpanel-browser';
import ReactGA4 from 'react-ga4';
import './App.css';
import './console-overrides';
import { register } from 'swiper/element/bundle';
import AccessibilityWidget from './AccessibilityWidget';

register();
configureAmplify();

function App() {
  const clinicType = process.env.REACT_APP_CETRA_CLINIC_TYPE;

  // Initialize Mixpanel with project token
  const mpToken = clinicType === 'vet'
    ? 'cf4c559bb22f7ba78ba1667adc6bdbe4'
    : '189e32a152f429590258281c65ec1492';
  mixpanel.init(mpToken, {
    verbose: true,
    debug: true,
    track_pageview: "url-with-path",
    persistence: 'localStorage'
  });

  // Initialize ReactGA4 with the Measurement ID
  useEffect(() => {
    const gtagId = process.env.REACT_APP_GTAG_ID;
    if (gtagId) {
      ReactGA4.initialize(gtagId);
    }
  }, []);

  return (
    <>
      <AuthenticatedAppWithStore
        AuthenticatedComponent={AuthenticatedApp}
        UnauthenticatedComponent={UnauthenticatedApp}
        createAppStateStore={createAppStateStore}
        theme={cetraLightModeTheme}
        globalComponents={
          <>
            <AppAuthStateObserver />
            <AccessibilityWidget />
          </>
        }
        globalAuthenticatedComponents={<ContentManager />}
      />
    </>
  );
}

export default observer(App);
