import React, {useContext} from 'react';
import { Box, Typography, Link, useTheme } from '@mui/material';
import StoreContext from '../../../react-core-lib/components/app/store-context';

const Copyright = () => {
  const theme = useTheme();
  const { userDataStore } = useContext(StoreContext);

  return (
    <>
      <Box
        component="footer"
        sx={{
          py: 1,
          // px: 2,
          textAlign: 'left',
          position: 'relative',
          '@media print': {
            position: 'fixed',
            bottom: '0',
            left: 0,
            width: '100%',
            bgcolor: 'background.paper', // Use theme color for consistency
            zIndex: 0,
            overflow: 'visible',
            py: 0,
            // display: 'inline'
          },
        }}
      >
        <Typography variant="caption" sx={{ lineHeight: '0.8', '@media print': {fontSize: '6pt', marginBottom: '-9px'}}}>
          © Copyright Cetra Health. {' '}
          <Box sx={{'@media (min-width: 600px)': {display: 'inline-block'}}}>
            All rights reserved.
          </Box>
          <br />
          <Link 
            href={process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 'https://www.cetravets.com' : 'https://www.cetrahealth.com'}
            color="inherit" 
            underline="always"
            sx={{
              '@media print': {
                textDecoration: 'none',
                lineHeight: '1', // Adjust the line height for better spacing
                display: 'block', // Ensure proper rendering of border
                marginTop: '-3px',
              },
            }}
            target="_blank" 
            rel="noopener"
          >
            {process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 'www.cetravets.com' : 'www.cetrahealth.com'}
          </Link>
        </Typography>
        {userDataStore?.userData?.clinicImageId && userDataStore?.userData?.showLogoInHandouts && (
            <img src={userDataStore.userData.clinicImageId} style={{position: 'absolute',
            right: 10,
            bottom: 2,
            // width: 48, 
            height: 75, 
            marginTop: 0}} cache={false} />
        )}  
      </Box>
    </>
  );
};

export default Copyright;
