import React from 'react';
import { Box } from '@mui/material';

/**
 * CenteredWrapper Component
 * 
 * @param {Object} props
 * @param {React.ReactNode} props.children - Inner content or child components.
 * 
 * @returns {React.ReactElement}
 */
export function CenteredWrapper({ children, component='div', sx}) {
  return (
    <Box 
      display="flex"             // Sets the display type to flex.
      flexDirection="column"     // Stacks child elements vertically.
      flex={1}                   // Takes up all available space in its container.
      p={4}                      // Adds padding of 4 units on all sides.
      justifyContent="center"    // Vertically centers the child content.
      alignItems="center"        // Horizontally centers the child content.
      component={component}
      sx={sx}
    >
      {children}
    </Box>
  );
}

/*
  The 'display: flex' property converts the box into a flex container.
  
  A flex container is the box on which we apply the 'display: flex' property. 
  It becomes the context in which flex items (the children) are laid out.
  
  Some properties of a flex container include:
  
  1. 'flexDirection': Determines the primary axis of the layout. Items are laid out 
     in rows by default, but can be laid out in columns if you set flexDirection to "column".
  
  2. 'justifyContent': Aligns the flex items along the primary axis of the flex container.
     For example, if items are laid out in rows, 'justifyContent' will horizontally align items.
  
  3. 'alignItems': Aligns the flex items along the cross axis of the flex container.
     For example, if items are laid out in rows, 'alignItems' will vertically align items.
  
  4. 'flexWrap': By default, flex items will all try to fit onto one line. You can change 
     that and allow the items to wrap onto multiple lines, if necessary, with this property.
  
  Flexbox is particularly useful when we want our layout to adapt to different screen sizes 
  (responsive design) or when we want to create layouts that are fluid and adjust to the content size.
*/
