import { useContext } from 'react';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import UserApi from '../../../rest-api/user-api';

export function useSendFeedback() {
  const { authStore, userDataStore } = useContext(StoreContext);

  const sendFeedback = async (topic, message) => {
    try {
      console.log(`Preparing to send feedback with topic: ${topic} and message: ${message} for user ${userDataStore}`);
      const result = await UserApi.sendPatientFeedback(authStore.idToken, topic, message,userDataStore.userData.firstName + ' ' + userDataStore.userData.lastName );
      return result;
    } catch (error) {
      console.error('Error sending feedback:', error);
      throw new Error('Failed to send feedback');
    }
  };

  return sendFeedback;
}
