// AppStore.js
import createAuthStore from './stores/auth-store';
import createContentStore from './stores/content-store';
import createImageStore from './stores/image-store';
import createSearchStore from './stores/search-store';
import createUserDataStore from './stores/user-data-store';
import createCategoryStore from './stores/category-store';

class AppStateStore {
  authStore;
  userDataStore;
  contentStore;
  imageStore;
  searchStore;
  categoryStore;
  userId;

  constructor() {
    this.authStore = createAuthStore((userId) => this.initializeUserStores(userId));
    // Do not initialize the other stores yet
  }

  // Function to initialize stores based on userId
  async initializeUserStores(userId) {
    console.log(`Initializing user stores for userId: ${userId}`);
    // Store the userId in the AppStateStore for future reference
    this.userId = userId;
    // Initialize stores with the userId
    this.userDataStore = await createUserDataStore(userId);
    this.contentStore = await createContentStore(userId);
    this.imageStore = await createImageStore(userId);
    this.searchStore = await createSearchStore(userId);
    this.categoryStore = await createCategoryStore(userId);
    console.log(`User stores initialized for userId: ${userId}`);
  }

  // Function to clear user-specific data when logging out
  async clearUserStores() {
    await this.userDataStore.deleteDatabase();
    await this.contentStore.deleteDatabase();
    await this.imageStore.deleteDatabase();

    // Reset the stores or perform cleanup if necessary
    this.userDataStore = null;
    this.contentStore = null;
    this.imageStore = null;
    this.searchStore = null;
    this.categoryStore = null;
    this.userId = null;
    console.log("User stores have been cleared.");
  }
}

const createAppStateStore = () => {
  console.log("Creating AppStateStore");
  return new AppStateStore();
};

export default createAppStateStore;
