import React, { useContext, useState, useMemo } from 'react';
import { Button, TextField, Typography, Stack, FormHelperText, List, ListItem, ListItemIcon, ListItemText, IconButton, InputAdornment, Link, Checkbox, FormControlLabel } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { OnboardingLayout } from './onboard-layout';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import ApiButton from '../../UIControls/api-button/api-button';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Auth } from 'aws-amplify';

const OnboardPasswordScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { authStore } = useContext(StoreContext);
  const userData = location.state?.userData || {};
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [agreeToTerms, setAgreeToTerms] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [error, setError] = useState('');

  console.log('Passed userData:', location.state?.userData);

  // Function to check individual requirements
  const passwordRequirements = [
    { test: pwd => pwd.length >= 8, text: "At least 8 characters" },
    { test: pwd => /[A-Z]/.test(pwd), text: "An uppercase letter" },
    { test: pwd => /[a-z]/.test(pwd), text: "A lowercase letter" },
    { test: pwd => /\d/.test(pwd), text: "A number" }
  ];

  const passwordCriteria = passwordRequirements.map(req => ({
    ...req,
    meets: req.test(password)
  }));

  const passwordsMatch = useMemo(() => password && confirmPassword && password === confirmPassword, [password, confirmPassword]);
  const passwordValid = useMemo(() => passwordCriteria.every(criterion => criterion.meets), [passwordCriteria]);
  const canSubmit = useMemo(() => passwordValid && passwordsMatch && agreeToTerms, [passwordValid, passwordsMatch, agreeToTerms]);

  const handleAgreeChange = (event) => {
    setAgreeToTerms(event.target.checked);
  };

  const signUp = async () => {
    if (!canSubmit) {
      setError('Please ensure all fields are filled correctly and agree to the terms and conditions.');
      return;
    }
  
    try {
      const result = await authStore.signUp(userData.emailAddress, password);
      if (result.success) {
        navigate('/onboard/code', { state: { userData } });
      } else {
        console.error('Error during authentication:', result.message);
        setError(result.message);
      }
    } catch (error) {
      console.error('Error during sign up:', error);
      if (error.message === "An account with the given email already exists." || error.message === "PreSignUp failed with error Internal Server Error.") {
        throw new Error("Sorry, we are unable to process your request at this time. Please contact us at hello@cetrahealth.com for further assistance.");
      } else if (error.message === "User already exists") {
        // User exists but isn't confirmed, so resend the confirmation code
        try {
          await Auth.resendSignUp(userData.emailAddress);
          authStore.setTempPassword(password);
          console.log('OTP code resent successfully.');
          navigate('/onboard/code', { state: { userData } });
        } catch (resendError) {
          console.error('Error resending OTP:', resendError);
          setError('Failed to resend verification code. Please try again or contact support.');
        }
      } else {
        // Handle other types of errors or rethrow the original error
        throw error;
      }
    }
  };
  

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowConfirmPassword = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };

  const renderInputChildren = () => (
    <Stack spacing={2}>
      <Typography variant="h6">Password</Typography>
      <TextField
        type={showPassword ? "text" : "password"}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
        error={!passwordValid}
        helperText={!passwordValid && "Password does not meet the requirements."}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <Typography variant="h6">Confirm Password</Typography>
      <TextField
        type={showConfirmPassword ? "text" : "password"}
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        fullWidth
        margin="normal"
        error={!passwordsMatch}
        helperText={!passwordsMatch && "Passwords do not match."}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle confirm password visibility"
                onClick={handleClickShowConfirmPassword}
                edge="end"
              >
                {showConfirmPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          )
        }}
      />
      <List dense>
        {passwordCriteria.map((criterion, index) => (
          <ListItem key={index} disablePadding>
            <ListItemIcon>
              {criterion.meets ? <CheckIcon color="success" /> : <CloseIcon color="error" />}
            </ListItemIcon>
            <ListItemText primary={criterion.text} />
          </ListItem>
        ))}
      </List>
      <FormControlLabel
        sx={{ marginLeft: '-8px !important' }}
        control={<Checkbox checked={agreeToTerms} onChange={handleAgreeChange} name="agreeToTerms" />}
        label={<Typography>I agree to the <Link href="https://www.cetravets.com/legal/terms-of-service" target="_blank" rel="noopener noreferrer">Terms and Conditions</Link>.</Typography>}
      />
      {error && <FormHelperText error>{error}</FormHelperText>}
    </Stack>
  );

  const renderNextButton = () => (
    <ApiButton
      apiCall={signUp}
      buttonLabel="Next"
      color="primary"
      disabled={!canSubmit}
      fullWidth={true}
    />
  );

  return (
    <OnboardingLayout
      title="Set your password"
      inputChildren={renderInputChildren()}
      navChildren={renderNextButton()}
      currentStep={4}
      previousStepPath="/onboard/email"
      userData={userData}
    />
  );
};

export default OnboardPasswordScreen;
