import { Text, View, StyleSheet } from '@react-pdf/renderer';

function PdfCallout({ content }) {
  // new lines are in CMS which don't show on handout view but will on PDF view so remove if needed
  const formattedContent = content.replace(/\n/g, ' ');

  return (
    <View style={styles.centeredContainer} wrap={false} >
      <View style={styles.shadow}></View>
      <View style={styles.calloutBox}>
        <View>
          <Text style={styles.calloutText}>{formattedContent}</Text>
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  centeredContainer: {
    margin: 20,
    position: 'relative',
    fontSize: '12px',
    display: 'block',
  },
  calloutBox: {
    border: '1 solid #000453',
    padding: 20,
    borderRadius: 16,
    width: '100%',
    backgroundColor: '#FFFFFF',
    zIndex: 1,
  },
  calloutText: {
    textAlign: 'justify',
    width: '100%',
    whiteSpace: 'nowrap'
  },
  shadow: {
    // border: '1 solid #000453',
    // padding: 20,
    // borderRadius: 8,
    // width: '100%',
    // height: '100%',
    // backgroundColor: '#0e93a0',
    // position: 'absolute',
    // zIndex: 2,
    // top: 8,    // Adjust this offset for vertical shadow positioning
    // right: 40,  // Adjust this offset for horizontal shadow positioning
  },
});

export default PdfCallout;
