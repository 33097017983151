import React from 'react';
import { Box } from '@mui/material';
import { CenteredWrapper } from '../../UIControls/centered-wrapper';
import { CenteredBox } from '../../UIControls/centered-box';

export function SearchLayout({ inputChildren, searchResultsChildren }) {

  return (
    <CenteredWrapper>
      {/* Main content */}
      <CenteredBox>
        {/* Inputs/Search bar at the top */}
        <Box display="flex" flexDirection="column" marginTop={'80px'} gap={4} width="100%" alignItems="stretch">
          {inputChildren}
        </Box>

        {/* Search Results */}
        <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="center">
          {searchResultsChildren}
        </Box>
      </CenteredBox>
    </CenteredWrapper>
  );
}
