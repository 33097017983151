import React, { useState } from 'react';
import { Button, TextField, Typography } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { OnboardingLayout } from './onboard-layout';

const OnboardEmailScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState({
    ...location.state?.userData,
    emailAddress: location.state?.userData?.emailAddress || '',
    mobile: location.state?.userData?.mobile || '',
  });

  console.log('Passed userData:', location.state?.userData);

  const handleChange = (key, value) => {
    setUserData({ ...userData, [key]: value });
  };

  const handleNext = () => {
    navigate('/onboard/password', { state: { userData } });
  };

  const isEmailValid = userData.emailAddress && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(userData.emailAddress);
  const isMobileValid = userData.mobile && /^\d+$/.test(userData.mobile); // Ensure mobile number contains only digits

  const isNextButtonDisabled = !isEmailValid || !isMobileValid; // Button disabled if email or mobile is invalid

  const renderInputFields = () => (
    <>
      <Typography variant="h6">What's your email?</Typography>
      <TextField
        type="email"
        value={userData.emailAddress}
        onChange={(event) => handleChange('emailAddress', event.target.value)}
        error={!isEmailValid && userData.emailAddress.length > 0}
        helperText={!isEmailValid && userData.emailAddress.length > 0 ? 'Invalid email address' : ''}
        required
        fullWidth
        margin="normal"
        placeholder="Enter your email address"
      />
      <Typography variant="h6">What's your mobile number?</Typography>
      <TextField
        type="text"
        value={userData.mobile}
        onChange={(event) => handleChange('mobile', event.target.value)}
        error={!isMobileValid && userData.mobile.length > 0}
        helperText={!isMobileValid && userData.mobile.length > 0 ? 'Mobile number must be numeric' : ''}
        required
        fullWidth
        margin="normal"
        placeholder="Enter your mobile number"
      />
    </>
  );

  const renderButtonGroup = () => (
    <Button
      variant="contained"
      color="primary"
      fullWidth
      onClick={handleNext}
      disabled={isNextButtonDisabled}
    >
      Next
    </Button>
  );

  return (
    <OnboardingLayout
      title="One more step!"
      inputChildren={renderInputFields()}
      navChildren={renderButtonGroup()}
      currentStep={3}
      previousStepPath="/onboard/clinic-name"
      userData={userData}
    />
  );
};

export default OnboardEmailScreen;
