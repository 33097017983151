import React, { createContext, useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const UnsavedChangesContext = createContext();

export const UnsavedChangesProvider = ({ children }) => {
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [nextPath, setNextPath] = useState('');
  const navigate = useNavigate();

  const promptUnsavedChanges = (navigateTo) => {
    if (hasUnsavedChanges) {
      setNextPath(navigateTo);
      setShowPrompt(true);
    } else {
      navigate(navigateTo);
    }
  };

  // Close the modal and reset states
  const onConfirmNavigation = () => {
    console.warn('stopping nav')
    setShowPrompt(false);
    navigate(nextPath);
    setHasUnsavedChanges(false);
  };

  const onCancelNavigation = () => {
    setShowPrompt(false);
  };

  return (
    <UnsavedChangesContext.Provider value={{ hasUnsavedChanges, setHasUnsavedChanges, promptUnsavedChanges, onConfirmNavigation, onCancelNavigation, showPrompt }}>
      {children}
    </UnsavedChangesContext.Provider>
  );
};

export const useUnsavedChanges = () => useContext(UnsavedChangesContext);

export { UnsavedChangesContext };
