import React, { useContext, useEffect, useState } from 'react';
import { SearchLayout } from '../search/search-layout';
import { SearchControls } from '../search/search-controls';
import { SearchResults } from '../search/search-results';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';

export const CategoriesResults = () => {
  const { contentStore, searchStore } = useContext(StoreContext);
  const [activeFilter, setActiveFilter] = useState('conditions');  
  const location = useLocation();

  // Extract and parse contentItems from the URL
  let contentItems = [];
  try {
    contentItems = JSON.parse(new URLSearchParams(location.search).get('contentItems')) || [];
  } catch (error) {
    console.error('Error parsing content items:', error);
  }

  const handleSearchInputChange = (value) => {
    console.log('Search input changed:', value);
    searchStore.setRawSearchTerm(value);
  };

  const handleFilterChange = (filterType) => {
    // Existing code...
  };

  // ... Other handler functions ...

  return (
    <SearchLayout
      inputChildren={
        <SearchControls
          searchTerm={searchStore.rawSearchTerm} // Use store state for input display
          onSearchChange={handleSearchInputChange}
          activeButton={activeFilter}
          onFilterChange={handleFilterChange}
        />
      }
      searchResultsChildren={<SearchResults results={contentItems} />}
    />
  );
};
