import React, { useContext, useEffect, useState } from 'react';
import { Typography, Box, Stack } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { OnboardingLayout } from './onboard-layout';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import ApiButton from '../../UIControls/api-button/api-button';
import ApiLinkText from '../../UIControls/api-button/api-link-text';
import UserApi from '../../../rest-api/user-api';
import VerificationCodeInput from './verification-code-input'; 
import ResendVerificationLink from './resend-verification-link';
import mixpanel from 'mixpanel-browser';
import klaviyoAPI from '../../../react-core-lib/third-party/klaviyoAPI';

const OnboardVerificationCodeScreen = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const userData = location.state?.userData || {};
  const appStateStore = useContext(StoreContext);
  const { authStore } = useContext(StoreContext);
  const [otp, setOtp] = useState('');
  const [otpComplete, setOtpComplete] = React.useState(false);

  const verifyCode = async (otp) => {
    const codeAsString = String(otp);
    console.log(`verifyCode called email: ${userData.emailAddress}, otp as string: ${codeAsString}`);
    const result = await authStore.confirmRegistration(userData.emailAddress, codeAsString, onRegistrationConfirmed);

    if (result.success) {
      // Code after successful verification
    } else {
      console.error('Error during authentication:', result.message);
      //alert(`Error during authentication: ${result.message}, cognito endpoint: ${process.env.REACT_APP_AWS_COGNITO_ENDPOINT}`);
      throw new Error(result.message);
    }
  };

  // This is your functor (callback function)
  const onRegistrationConfirmed = async (userId, idToken) => {
    console.log(`onRegistrationConfirmed called: userId: ${userId}, idToken: ${idToken}`);
    let payload = userData;
    payload.clinicImageId = "";
    payload.showLogoInHandouts = true;    
    payload.handouts = [];    

    try {
      await UserApi.updateClinicianData(idToken, payload);
      await mixpanel.track('New User Sign Up', {userId: userId, email: userData.emailAddress, 'clinicNumber': userData.clinicNumber ,'clinicName': userData.clinicName, 'mobile': userData.mobile, 'clinicSize': userData.clinicSize, 'userName': userData.firstName + ' ' + userData.lastName
      });
      await mixpanel.people.set({
        "$first_name": userData.firstName,
        "$last_name": userData.lastName,
        "$email": userData.emailAddress,
        "Mobile": userData.mobile,
        "Clinic Name": userData.clinicName,
        "Clinic Number": userData.clinicNumber,
        "Clinic Size": userData.clinicSize,
      });
      const subscriptionResult = await klaviyoAPI.subscribeUser(userData);
    } catch (error) {
      console.error('Error during post-registration updateClinicianData:', error);
    }

    // This will run regardless of the try/catch outcome
    try {
      await appStateStore.initializeUserStores(userId);
    } catch (error) {
      console.error('Error during post-registration initializeUserStores:', error);
    }
  };

  const handleResendVerificationCode = async () => {
    await authStore.resendVerificationCode(userData.emailAddress);
  };

  const ResendLink = () => (
    <ResendVerificationLink
      authStore={authStore}
      emailAddress={userData.emailAddress}
    />
  );


  const renderNextButton = () => (
    <ApiButton
      apiCall={() => verifyCode(otp)} // Assuming 'otp' is the state from `VerificationCodeInput`
      buttonLabel="Next"
      color="primary"
      errorTitle="Signup Error"
      errorMessage="There was a problem with the signup. Please try again."
      successTitle="Save Successful"
      successMessage="Your changes were saved successfully."
      disabled={!otpComplete} // Button is disabled unless the OTP is complete
      fullWidth={true}
    />
  );

  const renderInputChildren = () => (
    <Stack spacing={0} alignItems="center" justifyContent="center">
      <Typography variant="h4" align="center">
        OTP Verification
      </Typography>
      <Typography align="center">
        Enter the verification code we just sent to your email address
      </Typography>
      <VerificationCodeInput
        setOtpComplete={setOtpComplete}
        onOtpChange={(value) => setOtp(value)} // Assuming you have a setOtp method in your parent component
      />
      <ResendLink />
    </Stack>
  );

  return (
    <OnboardingLayout
      inputChildren={renderInputChildren()}
      navChildren={renderNextButton()}
      currentStep={5}
      previousStepPath="/onboard/password"
      userData={userData}
    />
  );
};

export default OnboardVerificationCodeScreen;
