import React from 'react';
import { Image, View, Text, StyleSheet } from '@react-pdf/renderer';

function PdfIconStrip({ iconDetails, loadedImages, textStyle }) {
  return (
    <View style={styles.iconStripContainer}>
      {iconDetails.map((icon) => {
        console.warn('pdfIconStip using: ', loadedImages)
        const base64Image = loadedImages.find(img => img.url.includes(icon.img));
        
        return (
          <View style={styles.iconItem} key={icon.img}>
            {base64Image 
              ? <Image src={base64Image.data.data} style={styles.iconImage} />
              : <Text  style={textStyle}>Image not found</Text>  
            }
            <Text wrap={false} style={[styles.iconCaption, textStyle]}>{icon.caption}</Text>
          </View>
        );
      })}
    </View>
  );
}

const styles = StyleSheet.create({
  iconStripContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between', // Adjust if necessary
    marginBottom: 10,  // Add more style as needed
  },
  iconItem: {
    width: '20%', // Assuming 5 icons for a row. Adjust as needed.
    margin: 1,    // Add space between icons
  },
  iconImage: {
    width: '100%',
    maxWidth: '140px',
    height: 'auto', // You might need to adjust this based on the aspect ratio of your icons
  },
  iconCaption: {
    textAlign: 'center',
    marginTop: 8,
    fontSize: 10,
  },
});

export default PdfIconStrip;
