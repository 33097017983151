import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import StoreContext from "../../react-core-lib/components/app/store-context";
import DownloadUtils from '../../utils/download-utils';
import { AuthState } from '../../stores/auth-store';

const ContentManager = observer(() => {
  const { authStore, contentStore } = useContext(StoreContext);
  const [fetchAttempts, setFetchAttempts] = useState(0);
  const [isFetchingSnapshot, setIsFetchingSnapshot] = useState(false);
  const [isSchedulingDiffs, setIsSchedulingDiffs] = useState(false);

  const fetchInitialSnapshot = async () => {
    setIsFetchingSnapshot(true);
    try {
      const result = await DownloadUtils.downloadAndExtractContentZip(authStore.idToken);
      await contentStore.setContentData(result.filter(content=>content.clinicType === process.env.REACT_APP_CETRA_CLINIC_TYPE));
      setIsSchedulingDiffs(true);
    } catch (error) {
      console.error('Failed to fetch initial snapshot:', error);
      setFetchAttempts(attempts => attempts + 1);
    } finally {
      setIsFetchingSnapshot(false);
    }
  };

  const scheduleDiffs = async () => {
    // console.log('FIXME: scheduleDiffs not implemented');
    const result = await DownloadUtils.downloadAndExtractUpdatedContent(authStore.idToken, contentStore.mostRecentLastUpdated);
    // Handle the result, e.g., update the contentStore with the new diffs
    console.log(`scheduleDiffs mostRecentLastUpdated: ${contentStore.mostRecentLastUpdated}, result json: ${JSON.stringify(result)}`);
    await contentStore.setContentData(result);
    // Set the next schedule after successful fetch
    setTimeout(scheduleDiffs, 60 * 60 * 1000); // Schedule the next diff after an hour
  };

  useEffect(() => {
    if (authStore.authState === AuthState.AUTHENTICATED && contentStore.isInitialized && !isFetchingSnapshot) {
      if (contentStore.hasContent()) {
        if (!isSchedulingDiffs) {
          scheduleDiffs(); // Call once before scheduling regularly
          setIsSchedulingDiffs(true);
        }
      } else {
        if (fetchAttempts < 3) {
          fetchInitialSnapshot();
        }
      }
    }
  }, [authStore.authState, contentStore.isInitialized, fetchAttempts, isFetchingSnapshot, isSchedulingDiffs]);

  return null;
});

export default ContentManager;
