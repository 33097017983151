import React from 'react';
import AuthStateObserver from '../../react-core-lib/components/global/auth-state-observer';

const AppAuthStateObserver = () => {

  const onAuthenticated = (location, navigate) => {
    if (location.pathname.includes('onboard')) {
      console.log('AppAuthStateObserver navigating to root');
      navigate('/');
    }
  };

  const onUninitialized = () => {
    // Maybe show a loading spinner or similar
  };

  const onUnauthenticated = (location, navigate) => {
    if (!location.pathname.includes('onboard')) {
      console.log('AppAuthStateObserver navigating to root');
      navigate('/');
    }
  };

  const onUnverified = (location, navigate, email) => {
    // console.log('Navigating to /verify');
    // navigate('/onboard/code', { state: { email: email } });
  };

  return (
    <AuthStateObserver
      onAuthenticated={onAuthenticated}
      onUninitialized={onUninitialized}
      onUnauthenticated={onUnauthenticated}
      onUnverified={onUnverified}
    />
  );
};

export default AppAuthStateObserver;
