import { Button } from '@mui/material';
import { styled } from '@mui/system';
import gradientImage from '../../assets/images/cetra-gradient_720.png';

const PinkGradientButton = styled(Button)(({ theme }) => ({
  // backgroundColor: 'blue', // Set default background color to blue
  backgroundImage: `url(${gradientImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  color: theme.palette.common.white,  // Assuming white text will complement the gradient
  '&:hover': {
    // You can adjust the hover effects here, for example:
    opacity: 0.9,
  }
}));

export default PinkGradientButton;
