import React from 'react';
import { Box, Button, Typography } from '@mui/material';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';

export function Feedback(props) {
  const navigate = useNavigate();

  const navigateToFeedback = () => {
    navigate('/feedback');
  };

  return (
    <Box
      bgcolor="#000453"
      p={2}
      borderRadius={2}
      display="flex"
      flexDirection="column"
      alignItems="start"
      gap={2}
      {...props}
    >
      <Typography variant="h4" align="left" color="white">
        Don't see what you're looking for?
      </Typography>
      <Typography color="white">
        We are always working on new content. Please suggest what you'd like to see!
      </Typography>

      <Button
        endIcon={<ArrowForwardIcon />}
        onClick={navigateToFeedback}
        variant="contained"
        style={{'backgroundColor': '#292F68', 'padding': '20px 24px','borderRadius': '36px'}}
      >
        Leave feedback
      </Button>
    </Box>
  );
}

