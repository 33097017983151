import React, { useContext, useEffect } from 'react';
import { List } from '@mui/material';
import { observer } from 'mobx-react-lite';
import CategoryListItem from './category-list-item';
import StoreContext from "../../../react-core-lib/components/app/store-context";

// Utility function to check for items in any level of subcategories
const hasItemsInSubcategories = (categoryData) => {
  // Check if the current level has items
  if (categoryData.items && categoryData.items.length > 0) {
    return true;
  }
  // Recursively check for items in subcategories
  return Object.values(categoryData.subcategories || {}).some(subcat => 
    hasItemsInSubcategories(subcat)
  );
};

export const SearchResults = observer(({ results }) => {
  const { categoryStore } = useContext(StoreContext);

  // console.warn('search results', results)

  return (
    <List>
      {Object.entries(results)
        .filter(([_, categoryData]) => 
          // Use the utility function to check for items in this category or any of its subcategories
          hasItemsInSubcategories(categoryData)
        )
        .map(([categoryName, categoryData], index) => (
          <CategoryListItem 
            key={index}
            categoryName={categoryName}
            contentItems={categoryData}
            isExpanded={categoryStore.expandedCategories[categoryName]}
            onToggleExpand={categoryStore.toggleCategoryExpand}
          />
        ))}
    </List>
  );
});
