import React from 'react';
import { Button, Box } from '@mui/material';
import arrowRight from '../../assets/icons/Arrow_Right.svg';

const gradientColors = ['#FA96C3', '#FF669B', '#BD8DE8'].join(',');

function GradientBorderButton({ text, image, imageSize, width }) {
  return (
    <Box
      sx={{
        display: 'inline-flex',
        background: `linear-gradient(to left, ${gradientColors})`,
        borderRadius: '36px',
        padding: '1px', // Creates the space for the gradient border
        width: width
      }}
    >
      <Button
        variant="contained"
        sx={{
          textTransform: 'none',
          backgroundColor: '#f2eff7',
          color: 'transparent', // Hide the original text color
          borderRadius: '35px', // Slightly less to fit inside the parent Box
          '&:hover': {
            backgroundColor: '#f2eff7',
            boxShadow: 'none',
            // '&:after': {
            //   content: '""',
            //   position: 'absolute',
            //   top: 0,
            //   left: 0,
            //   right: 0,
            //   bottom: 0,
            //   backgroundColor: 'rgba(232, 110, 123, 0.005)', // Semi-transparent overlay
            //   borderRadius: 'inherit',
            //   zIndex: 1,
            // }
          },
          width: '100%',
          height: '100%',
          overflow: 'hidden', // Ensure the pseudo-element does not overflow the button
          position: 'relative', // Establish positioning context
          boxShadow: 'none',
          padding: '18px',
          '&:before': { 
            content: `"${text}"`,
            position: 'absolute',
            top: 0,
            left: 16,
            bottom: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: `linear-gradient(to right, ${gradientColors})`,
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            fontSize: 'inherit', // Ensure the font size matches the Button component
            fontWeight: 'inherit', // Ensure the font weight matches the Button component
          },
        }}
      >
        <span style={{ visibility: 'hidden' }}>{text}</span>
        {image && imageSize && <img src={image} width={imageSize} style={{position: 'absolute', right: 18}}/>}
      </Button>
    </Box>
  );
}

export default GradientBorderButton;
