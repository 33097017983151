import React, { useState } from 'react';
import { Button, TextField, Typography, Box, MenuItem, FormControl, InputLabel, Select } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { OnboardingLayout } from './onboard-layout';
import arrowWhiteRight from '../../../assets/icons/Arrow-white_Right.svg';

const OnboardClinicNameScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [userData, setUserData] = useState({
    ...location.state?.userData,
    clinicName: location.state?.userData?.clinicName || '',
    clinicNumber: location.state?.userData?.clinicNumber || '',
    clinicSize: location.state?.userData?.clinicSize || '',
  });

  console.log('Passed userData:', location.state?.userData);

  const handleClinicNameChange = (event) => {
    setUserData({ ...userData, clinicName: event.target.value });
  };

  const handleClinicNumberChange = (event) => {
    const value = event.target.value;
    const re = /^[0-9\b]+$/; // Regex to allow only numbers
    if (value === '' || re.test(value)) {
      setUserData({ ...userData, clinicNumber: value });
    }
  };

  const handleClinicSizeChange = (event) => {
    setUserData({ ...userData, clinicSize: event.target.value });
  };

  const handleNext = () => {
    navigate('/onboard/email', { state: { userData } });
  };

  const handleSkip = () => {
    navigate('/onboard/email', { state: { userData: { ...userData, clinicName: '', clinicNumber: '', clinicSize: '' } } });
  };

  const isNextButtonDisabled = !userData.clinicName || !userData.clinicNumber || !userData.clinicSize;

  const renderClinicNameInput = () => (
    <>
      <Typography variant="h6">
        What's the name of your practice?
      </Typography>
      <TextField
        value={userData.clinicName}
        onChange={handleClinicNameChange}
        fullWidth
        margin="normal"
        placeholder="Enter clinic name"
      />
    </>
  );

  const renderClinicNumberInput = () => (
    <>
      <Typography variant="h6">
        What's your practice phone number?
      </Typography>
      <TextField
        type="tel"
        value={userData.clinicNumber}
        onChange={handleClinicNumberChange}
        fullWidth
        margin="normal"
        placeholder="Enter practice phone number"
      />
    </>
  );

  const renderPracticeSizeInput = () => (
    <>
      <Typography variant="h6" style={{ marginTop: 20 }}>
        Practice Size
      </Typography>
      <FormControl fullWidth margin="normal">
        <InputLabel id="practice-size-label">Select the size of your practise</InputLabel>
        <Select
          labelId="practice-size-label"
          id="practice-size-select"
          value={userData.clinicSize}
          onChange={handleClinicSizeChange}
        >
          <MenuItem value="0-5">0-5 staff</MenuItem>
          <MenuItem value="6-10">6-10 staff</MenuItem>
          <MenuItem value="11-20">11-20 staff</MenuItem>
          <MenuItem value="20+">20+ staff</MenuItem>
        </Select>
      </FormControl>
    </>
  );

  const renderButtonGroup = () => (
    <Box display="flex" justifyContent="space-between" mt={2}>
      <Button
        variant="outlined"
        color="primary"
        style={{ width: '48%' }}
        onClick={handleSkip}
      >
        I'll do this later
      </Button>
      <Button
        variant="contained"
        color="primary"
        style={{ width: '48%', position: 'relative' }}
        onClick={handleNext}
        disabled={isNextButtonDisabled}
      >
        Next
        <img
          src={arrowWhiteRight}
          alt='Right arrow'
          style={{
            position: 'absolute',
            marginLeft: 30,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)'
          }}
          width={18}
        />
      </Button>
    </Box>
  );

  return (
    <OnboardingLayout
      title="Almost there!"
      inputChildren={<>{renderClinicNameInput()}{renderClinicNumberInput()}{renderPracticeSizeInput()}</>}
      navChildren={renderButtonGroup()}
      currentStep={2}
      previousStepPath="/onboard/name"
      userData={userData}
    />
  );
};

export default OnboardClinicNameScreen;
