// UnauthenticatedApp.js

import React from 'react';
import { Route, Routes } from 'react-router-dom';
import SplashScreen from './splash/Splash';
import OnboardLoginScreen from './onboard/onboard-login';
import OnboardNameScreen from './onboard/onboard-name';
import OnboardClinicNameScreen from './onboard/onboard-clinic-name';
import OnboardEmailScreen from './onboard/onboard-email';
import OnboardPasswordScreen from './onboard/onboard-password';
import OnboardVerificationCodeScreen from './onboard/onboard-code';
import OnboardLoginResetPasswordScreen from './onboard/onboard-login-reset-password';

const UnauthenticatedApp = () => (
    <main id="main-content" style={{ zoom: document.documentElement.style.zoom || 1 }}>
        <Routes>
            <Route path="/" element={<SplashScreen />} />
            <Route path="/onboard/login" element={<OnboardLoginScreen />} />
            <Route path="/onboard/login/reset-password" element={<OnboardLoginResetPasswordScreen />} />
            <Route path="/onboard/name" element={<OnboardNameScreen />} />
            <Route path="/onboard/clinic-name" element={<OnboardClinicNameScreen />} />
            <Route path="/onboard/email" element={<OnboardEmailScreen />} />
            <Route path="/onboard/password" element={<OnboardPasswordScreen />} />
            <Route path="/onboard/code" element={<OnboardVerificationCodeScreen />} />     
        </Routes>
    </main>
);

export default UnauthenticatedApp;
