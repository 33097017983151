import React from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const BulletList = ({ items }) => (
  <List sx={{'@media print': {
    pageBreakInside: 'avoid',
    pageBreakBefore: 'auto', 
    py: 2,
    display: 'inline'
  } }}>
    {items.map((item, index) => (
      <ListItem key={index} sx={{position: 'relative',
      zIndex: 1, padding: '2px 8px','@media print': {
        pageBreakInside: 'avoid',
        pageBreakBefore: 'auto', 
        // display: 'inline'
      } }}>
        <ListItemIcon sx={{ minWidth: '32px', alignSelf: 'flex-start', marginTop: '10px'}}> {/* Adjust alignment here */}
          <FiberManualRecordIcon style={{ fontSize: '12px' }} />
        </ListItemIcon>
        <ListItemText primary={item} />
      </ListItem>
    ))}
  </List>
);

export default BulletList;
