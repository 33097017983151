import RestAPIUtils from "../react-core-lib/rest-api/rest-api-utils";

class UserApi {

  static USER_API_CONFIG = {
    apiUrl: process.env.REACT_APP_USER_API_URL,
    apiKey: process.env.REACT_APP_USER_API_KEY
  };

  static async updateClinicianData(bearerToken, payload) {
    const apiPath = `/v1/clinicians/update`;
    console.log(`updateClinicianData payload: ${JSON.stringify(payload)}`);
    return RestAPIUtils.makeWriteRequest(bearerToken, UserApi.USER_API_CONFIG, apiPath, 'PATCH', payload);
  }

  static async getClinicianData(bearerToken, payload) {
    const apiPath = `/v1/clinicians/query`;
    const params = {
      lastUpdated: Date.now()
    };
    console.log(`getClinicianData payload: ${JSON.stringify(payload)}`);
    return RestAPIUtils.makeGetRequest(bearerToken, UserApi.USER_API_CONFIG, apiPath, params);
  }

  static async generatedPresignedUrls(bearerToken, payload) {
    const apiPath = `/v1/user/generate-presigned-urls`;
    console.log(`generatedPresignedUrls payload: ${JSON.stringify(payload)}`);
    return RestAPIUtils.makeWriteRequest(bearerToken, UserApi.USER_API_CONFIG, apiPath, 'POST', payload);
  }

  static async createPresignedImagePutUrl(bearerToken, file) {
    const apiPath = `/v1/user/generate-presigned-urls`;

    const formattedFile = {
      key: file.key,
      fileContentType: file.fileContentType,
      fileSize: file.fileSize,
      method: "put"
    };

    // Create data object with contentCategory at the root and formattedFile directly
    const data = {
      files: [formattedFile], // API expect an array, so wrap the single file object in an array
      contentCategory: 'clinic_image',
    };

    console.log(`Requesting presigned URL with data: ${JSON.stringify(data)}`);

    return RestAPIUtils.makeWriteRequest(bearerToken, UserApi.USER_API_CONFIG, apiPath, 'POST', data);
}

  
  static async generatePatientLink(bearerToken, contentIds) {
    const apiPath = `/v1/user/content/generate-patient-link`;
    const payload = {
      contentIds: Array.isArray(contentIds) ? contentIds : [contentIds],
      locale: "en-UK"
    }
    console.warn(`generatePatientLink payload: ${JSON.stringify(payload)}`);
    return RestAPIUtils.makeWriteRequest(bearerToken, UserApi.USER_API_CONFIG, apiPath, 'POST', payload);
  }

  static async sendPatientEmail(bearerToken, emailAddress, contentName, linkId) {
    const apiPath = `/v1/user/content/send-patient-email`;
    const payload = {
      toEmailAddress: emailAddress,
      contentName: contentName,
      linkId: linkId,
    }
    console.log(`sendPatientEmail payload: ${JSON.stringify(payload)}`);
    return RestAPIUtils.makeWriteRequest(bearerToken, UserApi.USER_API_CONFIG, apiPath, 'POST', payload);
  }

  static async sendPatientFeedback(bearerToken, topic, message, name) {
    const apiPath = `/v1/user/content/send-patient-feedback`;
    const payload = {
      topic: topic,
      message: message,
      name: name
    }
    console.log(`sendPatientFeedback payload: ${JSON.stringify(payload)}`);
    return RestAPIUtils.makeWriteRequest(bearerToken, UserApi.USER_API_CONFIG, apiPath, 'POST', payload);
  }

  static getContentPresignedUrlPayload() {
    return {
      "files": [
        {
          "method": "get",
          "key": "snapshot.zip"
        }
      ],
      "contentCategory": "content"
    };
  };

  static async generatedContentPresignedUrl(bearerToken) {
    const apiPath = `/v1/user/generate-presigned-urls`;
    const payload = UserApi.getContentPresignedUrlPayload();
    console.log(`generatedContentPresignedUrl payload: ${JSON.stringify(payload)}`);
    return RestAPIUtils.makeWriteRequest(bearerToken, UserApi.USER_API_CONFIG, apiPath, 'POST', payload);
  }

  static async getUpdatedContent(bearerToken, lastUpdated) {
    const apiPath = '/v1/user/query-updated-content';
    const params = {
      clinicType:  process.env.REACT_APP_CETRA_CLINIC_TYPE,
      lastUpdated: lastUpdated
    };
    return RestAPIUtils.makeGetRequest(bearerToken, UserApi.USER_API_CONFIG, apiPath, params);
  }
}

export default UserApi;
