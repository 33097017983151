import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { SearchLayout } from '../search/search-layout';
import { SearchControls } from '../search/search-controls';
import { SearchResults } from './search-results';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import { observer } from 'mobx-react-lite';

export const CategoriesSearchScreen = observer(() => {
  const { contentStore, searchStore } = useContext(StoreContext);
  const [activeFilter, setActiveFilter] = useState('');
  const [categories, setCategories] = useState({});
  const [activeCategory, setActiveCategory] = useState(null);
  const [isSearchFocused, setIsSearchFocused] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const state = location.state || {};
    if (state.filter) {
      setActiveFilter(state.filter);
    }
  }, [location.state]);

  useEffect(() => {
    if (contentStore.isInitialized) {
      // Use the body area or animal data from the store based on Cetra type
      setCategories(contentStore.getCategoriesData()[process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 'Animal' : 'Body Area']);
    }
    const value = searchStore.rawSearchTerm;

    if (value.trim()) {
      searchStore.setSearchTerm(value); // setSearchTerm already trims the term
      const results = contentStore.searchContentMeta(value.trim());
      searchStore.setSearchResults(results);
    } else {
      searchStore.setSearchTerm('');
      searchStore.setSearchResults([]);
    }
  }, [contentStore, searchStore.rawSearchTerm]); 
  
  const handleFilterChange = (filterType) => {
    console.warn(filterType);
    setActiveFilter(filterType);
    navigate('/search', { state: { filter: filterType } });
  };

  const handleSearchInputChange = (value) => {
    console.log('Search input changed:', value);
    setActiveFilter('Condition')
    searchStore.setRawSearchTerm(value);
  };

  const handleCategoryChange = (category) => {
    console.warn(category);
    setActiveCategory(category);
    navigate('/search', { state: { category: category } });
  };

  return (
    <SearchLayout
      inputChildren={
        <SearchControls
          searchTerm={searchStore.rawSearchTerm} // Use store state for input display
          onSearchChange={handleSearchInputChange}
          activeFilter={activeFilter}
          onFilterChange={handleFilterChange}
          activeCategory={activeCategory}
          onCategoryChange={handleCategoryChange}
        />
      }
      searchResultsChildren={<SearchResults results={categories} />}        
    />
  );
});
