import React from 'react';
import Switch from '@mui/material/Switch';
import { styled } from '@mui/material/styles';

const AntSwitch = styled(Switch)(({ theme }) => ({
  width: 60,
  height: 33.5,
  padding: 0,
  marginTop: 6,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 33,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {
      transform: 'translateX(25px)',
    },
  },
  '& .MuiSwitch-switchBase': {
    padding: '0px',
    '&.Mui-checked': {
      transform: 'translateX(27px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: theme.palette.mode === 'dark' ? '#177ddc' : '#E5E5E5',
      },
      '& .MuiSwitch-thumb': {
        background: theme.palette.mode === 'dark' ? '#616063' : 'linear-gradient(131.61deg, #FA96C3 -2.07%, #FF669B 15.82%, #BD8DE8 116.64%)',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    background: '#616063',
    boxShadow: 'none',
    width: 33, 
    height: 33, 
  },
  '& .MuiSwitch-track': {
    borderRadius: 20,
    opacity: 1,
    backgroundColor: theme.palette.mode === 'dark' ? '#616063' : 'rgba(0,0,0,.25)',
    boxSizing: 'border-box',
    width: '100%', 
    height: '100%', 
  },
}));

export default AntSwitch;
