import { CATEGORIES, CATEGORIES_VET } from './categories';
import { toJS } from 'mobx';

class CategoryManager {
  constructor(contentData) {
    this.contentData = toJS(contentData)
    const clinicType = process.env.REACT_APP_CETRA_CLINIC_TYPE;
    this.categoriesConfig = clinicType === 'vet' ? CATEGORIES_VET : CATEGORIES;
    this.parsedData = {};
    this.initializeCategories();
  }

  initializeCategories() {
    Object.entries(this.categoriesConfig).forEach(([categoryTypeKey, categoriesArray]) => {
      this.parsedData[categoryTypeKey] = {};
      categoriesArray.forEach(category => {
        this.parsedData[categoryTypeKey][category.name] = {
          type: 'category',
          items: [],
          subcategories: category.subcategories.reduce((acc, subcategory) => {
            acc[subcategory] = { type: 'subcategory', items: [] };
            return acc;
          }, {})
        };
      });
    });
  }

  categorizeContent() {
    Object.values(this.contentData).forEach(item => {
        Object.entries(item.categories || {}).forEach(([categoryTypeKey, categoriesOrSubcategories]) => {
            if (!categoriesOrSubcategories || Object.keys(categoriesOrSubcategories).length === 0) {
                this.addDirectlyToCategory(categoryTypeKey, item);
            } else {
                this.handleSubcategories(categoryTypeKey, categoriesOrSubcategories, item);
            }
        });
    });
    this.removeEmptyCategoriesAndSubcategories(this.parsedData);
    // console.warn('parsed data', this.parsedData)
  }

  // Helper methods below
  addDirectlyToCategory(categoryTypeKey, item) {
      if (!this.parsedData[categoryTypeKey]) {
          this.parsedData[categoryTypeKey] = { items: [] };
      } else if (!this.parsedData[categoryTypeKey].items) {
          this.parsedData[categoryTypeKey].items = [];
      }
      this.parsedData[categoryTypeKey].items.push({
          contentId: item.contentId,
          contentName: item.contentName,
      });
  }

 // Adjusted to handle both straightforward and nested subcategory structures.
  handleSubcategories(categoryTypeKey, categoriesOrSubcategories, item) {
    Object.entries(categoriesOrSubcategories).forEach(([categoryKey, subcategoryValues]) => {
      subcategoryValues.forEach(subcategoryValue => {
        if (typeof subcategoryValue === 'string') {
          // Direct mapping: Category > Subcategory
          this.addOrUpdateSubcategory(categoryTypeKey, categoryKey, subcategoryValue, item);
        } else if (typeof subcategoryValue === 'object') {
          // Nested structure: Category > { Subcategory > Sub-subcategory }
          Object.entries(subcategoryValue).forEach(([subcategoryKey, deepSubcategoryValues]) => {
            deepSubcategoryValues.forEach(deepSubcategoryValue => {
              this.addOrUpdateSubcategory(categoryTypeKey, categoryKey, subcategoryKey, item, deepSubcategoryValue);
            });
          });
        }
      });
    });
  }

  addOrUpdateSubcategory(categoryTypeKey, categoryKey, subcategoryKey, item, deepSubcategoryValue = null) {
    let category = this.parsedData[categoryTypeKey][categoryKey];
    if (!category) {
      category = this.parsedData[categoryTypeKey][categoryKey] = { type: 'category', items: [], subcategories: {} };
    }

    if (!category.subcategories[subcategoryKey]) {
      category.subcategories[subcategoryKey] = { type: 'subcategory', items: [], subcategories: {} };
    }

    if (deepSubcategoryValue) {
      // If there's a deeper subcategory, handle that
      if (!category.subcategories[subcategoryKey].subcategories[deepSubcategoryValue]) {
        category.subcategories[subcategoryKey].subcategories[deepSubcategoryValue] = { type: 'subcategory', items: [] };
      }
      category.subcategories[subcategoryKey].subcategories[deepSubcategoryValue].items.push({
        contentId: item.contentId,
        contentName: item.contentName,
      });
    } else {
      // Otherwise, add directly to the subcategory's items
      category.subcategories[subcategoryKey].items.push({
        contentId: item.contentId,
        contentName: item.contentName,
      });
    }
  }

  // Adjusted method signature and logic to match usage
  addToCategory(categoryTypeKey, categoryKey, subcategoryKey, item) {
    let categoryPath = this.parsedData[categoryTypeKey];
    if (!categoryPath) {
        console.warn(`Category type key '${categoryTypeKey}' not found.`);
        return;
    }
    let subcategoryPath = categoryPath[categoryKey] && categoryPath[categoryKey].subcategories[subcategoryKey];
    if (!subcategoryPath) {
        console.warn(`Subcategory '${subcategoryKey}' under '${categoryKey}' in '${categoryTypeKey}' not found.`);
        // Initialize it if you want to dynamically add missing categories/subcategories
        if (!categoryPath[categoryKey]) categoryPath[categoryKey] = { type: 'category', items: [], subcategories: {} };
        if (!categoryPath[categoryKey].subcategories[subcategoryKey]) categoryPath[categoryKey].subcategories[subcategoryKey] = { type: 'subcategory', items: [] };
        subcategoryPath = categoryPath[categoryKey].subcategories[subcategoryKey];
    }
    subcategoryPath.items.push({
        contentId: item.contentId,
        contentName: item.contentName,
    });
  }

  removeEmptyCategoriesAndSubcategories(data = this.data) {
    Object.entries(data).forEach(([key, value]) => {
      if (value.type === 'category' || value.type === 'subcategory') {
        // If it's a category or subcategory, check its items and subcategories
        if (!value.items || value.items.length === 0) {
          delete value.items; // Remove empty items array
        }
        if (value.subcategories) {
          this.removeEmptyCategoriesAndSubcategories(value.subcategories); // Recurse into subcategories
          if (Object.keys(value.subcategories).length === 0) {
            delete value.subcategories; // Remove empty subcategories object
          }
        }
        // After cleaning subcategories and items, if both are gone, remove the category/subcategory itself
        if (!value.items && !value.subcategories) {
          delete data[key];
        }
      } else if (typeof value === 'object') {
        // If the value is an object but not a category/subcategory, it might be a top-level object like "Animal" or "Body Area"
        this.removeEmptyCategoriesAndSubcategories(value); // Recurse into it
        // After recursion, if the object is empty (all its categories/subcategories were removed), then remove it
        if (Object.keys(value).length === 0) {
          delete data[key];
        }
      }
    });
  }

  cleanSubcategories(subcategories) {
      Object.keys(subcategories).forEach(key => {
          const subcategory = subcategories[key];
          if (subcategory.type === 'subcategory') {
              if (subcategory.subcategories) {
                  this.cleanSubcategories(subcategory.subcategories);
              }
              if (this.isEmptyCategory(subcategory)) {
                  delete subcategories[key];
              }
          }
      });
  }

  isEmptyCategory(category) {
      const hasNoItems = !category.items || category.items.length === 0;
      const hasNoSubcategories = !category.subcategories || Object.keys(category.subcategories).length === 0 || Object.values(category.subcategories).every(subcat => this.isEmptyCategory(subcat));
      return hasNoItems && hasNoSubcategories;
  }

  logCategorizedContent() {
    console.log('categorised content:', JSON.stringify(this.parsedData, null, 2));
  }
}

export default CategoryManager;
