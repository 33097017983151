import React from 'react';
import { Typography, Button, Box, Container, useMediaQuery, styled } from '@mui/material';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import PinkGradientButton from '../../UIControls/pink-gradient-button';
import logoImage from '../../../assets/images/BlackLogo.png';
// import cetraLogo from '../../../assets/logos/cetra-01-plain.svg';
import cetraLogo from '../../../assets/logos/cetra-01-optimized.svg';

const BackgroundLogo = styled('div')(({ isMobile }) => ({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  backgroundImage: `url(${logoImage})`,
  backgroundSize: isMobile ? '200%' : '100%',
  backgroundPosition: isMobile ? 'left center' : 'center',
  backgroundRepeat: 'no-repeat',
  opacity: 0.1,
  zIndex: -1,
}));

const SplashScreen = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));

  return (
    <Container
      sx={{
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100vh',
        padding: 4,
        textAlign: 'left' // Aligns everything inside the container to the left
      }}
    >
      <BackgroundLogo isMobile={isMobile} />

      <Box sx={{ mb: 2 }}>
        <img
          src={cetraLogo}
          alt="Cetra Logo"
          style={{ height: isMobile ? '80px' : '120px' }}
        />
      </Box>

      <Typography variant="h4" gutterBottom>
        {process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 
        (
          'Pet Owner Education.' 
        ) 
        : (
          'Patient Education.' 
        )
        }
        <span style={{ fontWeight: 'bold' }}> Reimagined.</span>
      </Typography>

      <Box sx={{ mt: 4, display: "flex", flexDirection: "column", gap: 2 }}>
        <PinkGradientButton variant="contained" color="primary" onClick={() => navigate('/onboard/login')}>
          Get Started
        </PinkGradientButton>
        {/* <Button variant="contained" color="primary" component={Link} to="/search/search">
          Search
        </Button>
        <Button variant="contained" color="primary" component={Link} to="/handout/6eb789e2-6569-405f-ac53-a75cf69af2a4">
          View handout
        </Button> */}
      </Box>
    </Container>
  );
};

export default SplashScreen;
