import React from 'react';
import { useTheme, useMediaQuery } from '@mui/material';
import ApiIconButton from '../../UIControls/api-button/api-icon-button';
import CustomToolbarButton from './toolbar/custom-toolbar-button';
import ASWhatsAppIcon from '../../../assets/icons/Whatsapp.svg';
import ASShareIcon from '../../../assets/icons/Share.svg';
import mixpanel from 'mixpanel-browser';

const ShareButton = ({ generateLink, currentUser, bundledHandoutsView = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleShare = async () => {    
    const { title, url, message } = await generateLink();
    console.warn('share whatsapp message', message)

    if (isMobile && navigator.share) {
      return navigator.share({
        title: title,
        text: message,
      });
    } else {
      const whatsappUrl = `https://wa.me/?text=${encodeURIComponent(message)}`;
      window.open(whatsappUrl, '_blank');
    }

    mixpanel.track('HandoutShared', {
      email: currentUser.attributes.email,
      shareMethod: 'WhatsApp',
      handoutTitle: title.replace('Information Sheet: ', '')
    });
  };

  const iconSrc = isMobile ? ASShareIcon : ASWhatsAppIcon;

  return (
    <ApiIconButton
      apiCall={handleShare}
      icon={<CustomToolbarButton iconSrc={iconSrc} tooltipTitle={bundledHandoutsView ? "Share handouts" : "Share handout"} />}
      buttonStyle={{ color: theme.palette.customColors.iconColor }}
      errorTitle={"WhatsApp Share Error"}
      errorMessage="There was an error sharing. Please try again."
      onSuccess={() => {}}
      // successTitle={isMobile ? "Share Successful" : "WhatsApp Share Successful"}
      // successMessage="The link was shared successfully."
      ariaLabel={bundledHandoutsView ? "Share handouts" : "Share handout"}
    />
  );
};

export default ShareButton;
