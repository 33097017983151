import React from 'react';
import { MuiOtpInput } from 'mui-one-time-password-input';
import { useMediaQuery, useTheme } from '@mui/material';

const VerificationCodeInput = ({ setOtpComplete, onOtpChange }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [otp, setOtp] = React.useState('');

  const handleChange = (newValue) => {
    console.log(`handleChange called with newValue: ${newValue}`);
    setOtp(newValue);
    onOtpChange(newValue);
    setOtpComplete(newValue.length === 6);
  };

  const textFieldProps = isMobile
    ? {
        type: 'number',
        inputMode: 'numeric',
        pattern: '[0-9]*',
        sx: { width: '38px', marginX: '0px', marginY: '24px' },
      }
    : {
        sx: { width: '40px', marginX: '2px', marginY: '36px' },
      };

  return (
    <MuiOtpInput
      value={otp}
      onChange={handleChange}
      length={6}
      TextFieldsProps={textFieldProps}
    />
  );
};

export default VerificationCodeInput;
