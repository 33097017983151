import React from 'react';
import { Box, Button } from '@mui/material';
import canineIcon from '../../../assets/icons/categories/Canine.svg';
import felineIcon from '../../../assets/icons/categories/Feline.svg';
import hamsterIcon from '../../../assets/icons/categories/Hamster.svg';
import equineIcon from '../../../assets/icons/categories/Equine.svg';
import smallAnimalsIcon from '../../../assets/icons/categories/Small-animals.svg';
import largeAnimalsIcon from '../../../assets/icons/categories/Large-animals.svg';
import smallMammalsIcon from '../../../assets/icons/categories/Small-mammals.svg';

import { observer } from 'mobx-react-lite';

const filterIconMap = {
  'Canine': canineIcon,
  'Feline': felineIcon,
  'Equine': equineIcon,
  'Small mammals': smallMammalsIcon,
  'Large Animals': largeAnimalsIcon,
};

export const SearchFiltersVet = observer(({ activeFilter, onFilterChange }) => {
  const handleFilterClick = (filter, event) => {
    event.preventDefault(); 
    if (activeFilter === filter){onFilterChange('Condition')} 
    else {onFilterChange(filter)};
  };
  return (
    <Box sx={{marginTop: '8px!important'}}>
      {Object.entries(filterIconMap).map(([filter, icon]) => (
        <Button
          key={filter}
          className='category-btn'
          sx={{
            color: activeFilter === filter ? 'white' : 'black',
            backgroundColor: activeFilter === filter ? '#FC96A2' : '#f7f7f7',
            borderRadius: '16px',
            marginRight: 1,
            marginTop: 1,
            height: 48,
            fontSize: 12,
            '&:hover': {
              backgroundColor: activeFilter === filter ? '#FC96A2' : '#f7f7f7',
            },
          }}
          onClick={(e) => handleFilterClick(filter, e)}
          onTouchEnd={(e) => handleFilterClick(filter, e)}
        >
          <img
            src={icon}
            alt={`${filter} Filter Icon`}
            width="24px"
            height="24px"
            style={{
              position: 'relative',
              top: 0,
              marginRight: 4,
              right: 0,
              zIndex: 1,
              filter: activeFilter === filter ? 'invert(100%)' : 'initial',
            }}
          />
          {filter.replace("Animals", "animals")}
        </Button>
      ))}
    </Box>
  );
});

