import axios from 'axios';

class RestAPIUtils {

  static async makeGetRequestNoAuth(config, apiPath, params = {}) {
    const { apiUrl, apiKey } = config;

    const headers = {
      'x-api-key': apiKey
    };

    try {
      console.log(`makeGetRequestNoAuth API at ${apiUrl}${apiPath}`);
      console.log(`Headers: ${JSON.stringify(headers)}`);

      const response = await axios.get(
        `${apiUrl}${apiPath}`,
        {
          headers: headers,
          params: params
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Received status code ${response.status}`);
      }
    } catch (error) {
      console.error(`An error occurred: ${error}`);
      throw error;
    }
  };

  static async makeGetRequest(bearerToken, config, apiPath, params = {}) {
    const { apiUrl, apiKey } = config;

    if (!bearerToken || bearerToken.trim() === '') {
      console.error('Bearer token is null or empty');
      throw new Error('Bearer token is null or empty');
    }

    const headers = {
      'Authorization': `Bearer ${bearerToken}`,
      'x-api-key': apiKey
    };

    try {
      console.log(`makeGetRequest API at ${apiUrl}${apiPath}`);
      console.log(`Headers: ${JSON.stringify(headers)}`);

      const response = await axios.get(
        `${apiUrl}${apiPath}`,
        {
          headers: headers,
          params: params
        }
      );

      if (response.status === 200) {
        return response.data;
      } else {
        throw new Error(`Received status code ${response.status}`);
      }
    } catch (error) {
      console.error(`An error occurred: ${error}`);
      throw error;
    }
  };

  static async makeWriteRequest(bearerToken, config, apiPath, method, data = {}) {
    const { apiUrl, apiKey } = config;

    if (!bearerToken || bearerToken.trim() === '') {
      console.error('Bearer token is null or empty');
      throw new Error('Bearer token is null or empty');
    }

    if (!apiPath || apiPath.trim() === '') {
      console.error('API path is null or empty');
      throw new Error('API path is null or empty');
    }

    if (!method || method.trim() === '') {
      console.error('Method is null or empty');
      throw new Error('Method is null or empty');
    }

    const headers = {
      'Authorization': `Bearer ${bearerToken}`,
      'x-api-key': apiKey,
      'Content-Type': 'application/json'
    };

    try {
      console.log(`Calling API at ${apiUrl}${apiPath}`);
      console.log(`Headers: ${JSON.stringify(headers)}`);

      const response = await axios({
        method: method,
        url: `${apiUrl}${apiPath}`,
        headers: headers,
        data: data
      });

      if (response.status === 200 || response.status === 201) {
        return response.data;
      } else {
        throw new Error(`Received status code ${response.status}`);
      }
    } catch (error) {
      console.error(`An error occurred: ${error}`);
      throw error;
    }
  };


}

export default RestAPIUtils;