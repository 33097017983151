import React, { useContext } from 'react';
import { Box, Button } from '@mui/material';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import { CenteredWrapper } from '../../UIControls/centered-wrapper';
import { CenteredBox } from '../../UIControls/centered-box';
import DownloadUtils from '../../../utils/download-utils';
import { useNavigate } from 'react-router-dom';

export function SettingsDevScreen() {
  const appStateStore = useContext(StoreContext);
  const { authStore, contentStore, imageStore } = useContext(StoreContext);
  const navigate = useNavigate();

  const handleDownloadSnapshot = async () => {
    try {
      const result = await DownloadUtils.downloadAndExtractContentZip(authStore.idToken);
      console.log(`Download and extraction result json: ${JSON.stringify(result, null, 2)}`);
      contentStore.setContentData(result);
    } catch (error) {
      console.error("Error downloading and extracting snapshot:", error);
    }
  };

  const handleDownloadDiff = async () => {
    try {
      const result = await DownloadUtils.downloadAndExtractUpdatedContent(authStore.idToken, 1697551087265);
      console.log(`Download and extraction result json: ${JSON.stringify(result, null, 2)}`);
      //contentStore.setContentData(result);
    } catch (error) {
      console.error("Error downloading and extracting snapshot:", error);
    }
  };

  const handleSignout = async () => {
    console.log('handleSignout');
    await authStore.logout();
    await appStateStore.clearUserStores();
    localStorage.clear();
  };

  const handleClearCache = async () => {
    console.log('handleClearCache');
    await imageStore.clearStore();
    await contentStore.clearStore();
  };

  const handleDeleteCache = async () => {
    console.log('handleDeleteCache');
    await imageStore.deleteDatabase();
    await contentStore.deleteDatabase();
  };

  const handleCodeInput = async () => {
    console.log('handleCodeInput');
    navigate('/code-input');
  };

  return (
    <CenteredWrapper>
      <CenteredBox>
        <Box display="flex" flexDirection="column" gap={4} alignItems="flex-start">
            <Button variant="outlined" color="primary" onClick={handleDownloadSnapshot}>
                Download snapshot
            </Button>
            <Button variant="outlined" color="primary" onClick={handleDownloadDiff}>
                Download diff
            </Button>
            <Button variant="outlined" color="primary" onClick={handleSignout}>
                Sign out
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleClearCache}>
                Clear Cache(s)
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleDeleteCache}>
                Delete Cache(s)
            </Button>
            <Button variant="outlined" color="secondary" onClick={handleCodeInput}>
                Test Code Input
            </Button>
        </Box>
      </CenteredBox>
    </CenteredWrapper>
  );
}