import React, { useState, useEffect, useRef } from 'react';
import UserApi from '../../../../rest-api/user-api';
import DownloadUtils from '../../../../utils/download-utils';

console.log('HandoutPdfImageLoader');

// Utility function to convert blob to base64
const convertBlobToBase64 = (blob) => new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
        // Optionally remove the prefix `data:application/octet-stream;base64,` if present
        let base64Data = reader.result.replace(/^data:.*;base64,/, '');
        resolve(base64Data);
    };
    reader.readAsDataURL(blob);
});

function HandoutPdfImageLoader({ handoutData, authStore, imageStore, onImagesLoaded }) {
  const [loadedImages, setLoadedImages] = useState([]);
  const isMountedRef = useRef(true);

  useEffect(() => {
    console.log('HandoutPdfImageLoader useEffect');
    loadOrFetchImages();
    return () => {
      isMountedRef.current = false;
  };
  }, []);

  function extractImageUrls() {
    const extractedUrls = [];
    handoutData.contentData['en-UK'].forEach(section => {
      section.blocks.forEach(block => {
        if (block.type === 'iconStrip') {
          block.iconDetails.forEach(icon => extractedUrls.push(icon.img));
        } else if (block.type === 'illustration') {
          extractedUrls.push(block.src);
        }
      });
    });
    return extractedUrls;
  }

  async function loadOrFetchImages() {
    console.log('loadOrFetchImages');
    const imageUrls = extractImageUrls();
    console.warn('imageUrls:', imageUrls);
    const idToken = authStore.idToken;

    const cachedImages = await Promise.all(imageUrls.map(async (url) => {
      const imageData = await imageStore.getImageFromIndexedDB(url);
      return { url, data: imageData };
    }));

    console.warn('cachedImages:', cachedImages);

    if (isMountedRef.current) {
      setLoadedImages(cachedImages);
      if (onImagesLoaded) {
          onImagesLoaded(cachedImages);
      }
  }


    console.warn('first cachedImages: ', cachedImages[0].data)

    const uncachedUrls = imageUrls.filter((url, index) => !cachedImages[index].data.data);

    if (uncachedUrls && uncachedUrls.length) {
      const payload = {
        files: uncachedUrls.map(url => ({ method: "get", key: url })),
        contentCategory: "image"
      };

      const presignedUrls = await UserApi.generatedPresignedUrls(idToken, payload);
      console.warn("presignedUrls",presignedUrls)
      const newImages = await Promise.all(
        presignedUrls.map(async (result) => {
          const blob = await DownloadUtils.downloadImageFromPresignedUrl(result.presignedUrl);
          return convertBlobToBase64(blob); // Convert the binary data to base64
        })
      );

      await Promise.all(newImages.map(async (base64Image, index) => {
        const url = uncachedUrls[index];
        await imageStore.saveImageToIndexedDB(url, base64Image); // Save the base64 image data
        const indexToUpdate = cachedImages.findIndex(imgObj => imgObj.url === url);
        cachedImages[indexToUpdate].data.data = base64Image; // Update the cached image data
      }));
    } else {
      console.warn('else cachedImages load:', cachedImages);
      onImagesLoaded(cachedImages);
    }

    console.warn('uncachedUrls:', uncachedUrls);

    setLoadedImages(cachedImages);

    if (onImagesLoaded) {
      onImagesLoaded(cachedImages);
    }
  }

  return null;
}

export default HandoutPdfImageLoader;
