import Fuse from 'fuse.js';

class FuseWrapper {
  constructor(jsonData) {
    console.log('FuseWrapper constructor');
    
    // Filter jsonData first for items that are not deleted and are published
    const filteredData = Object.keys(jsonData).reduce((accumulator, key) => {
      const item = jsonData[key];
      if (item.isDeleted !== true && item.contentStatus === "published") {
        // Dynamically process all categories
        const categoriesContent = this.extractCategoriesData(item.categories);

        accumulator[key] = {
          ...item,
          categoriesContent, // Add processed categories content for searching
          contentData: this.extractRawData(item.contentData),
        };
      }
      return accumulator;
    }, {});

    const processedData = Object.keys(filteredData).length !== 0 ?
      Object.values(filteredData) : [];

    console.log(`processed data length: ${processedData.length}`);

    const fuseOptions = {
      keys: ['contentId', 'contentName', 'categoriesContent', 'contentData'],
      includeScore: true,
      threshold: 0.3,
      tokenize: true,
      matchAllTokens: true,
      location: 0,
      distance: 100,
      ignoreLocation: true,
      ignoreCase: true,
    };

    this.fuse = new Fuse(processedData, fuseOptions);
  }

  extractCategoriesData(categories) {
    // Assuming each category is an object with similar structure to Body Area
    let categoriesData = '';
    for (const category in categories) {
      const categoryData = categories[category];
      // Concatenate the data from each category
      categoriesData += ' ' + this.extractRawData(categoryData);
    }
    return categoriesData.trim();
  }

  stripCmsTags(block) {
    switch (block.type) {
      case 'documentTitle':
      case 'sectionTitle':
      case 'calloutSmall':
      case 'calloutMedium':
      case 'paragraph':
        return block.value || '';
      case 'list':
        return (block.values && block.values.join(' ')) || '';
      case 'iconStrip':
        return (block.iconDetails && block.iconDetails.map(icon => icon.caption).join(' ')) || '';
      case 'illustration':
        return block.alt || '';
      default:
        return '';
    }
  }

  extractRawData(data) {
    if (typeof data === 'object' && data['en-UK']) {
      const rawData = [];
      data['en-UK'].forEach(section => {
        if (Array.isArray(section.blocks)) {
          section.blocks.forEach(block => {
            rawData.push(this.stripCmsTags(block));
          });
        }
      });
      return rawData.join(' ');
    } else if (typeof data === 'object') {
      return Object.values(data).join(' ');
    }
    return '';
  }

  search(query) {
    console.warn('test', query);
    return this.fuse.search(query);
  }

  searchContentMeta(query) {
    console.warn('test', query);
    const results = this.search(query);
    //console.log(`searchContentMeta results: ${JSON.stringify(results)}`);
    return results
      // Filter out results with isDeleted === true
      .filter(result => !(result.item.isDeleted === true))
      .map(result => ({
        contentId: result.item.contentId,
        contentName: result.item.contentName
      }));
  }  

}

export default FuseWrapper;
