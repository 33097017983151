import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { ListItem, ListItemText, ListItemIcon, Typography, ButtonBase } from '@mui/material';

const ContentListItem = ({breadcrumbs, contentName, contentId, indentation = '0em', onHandoutSelect }) => (
  <ListItem
    component="li"
    onClick={() => onHandoutSelect(contentName)} 
    secondaryAction={
      <ListItemIcon>
        <RouterLink to={`/handout/${contentId}`} state={{breadcrumbs: breadcrumbs}} style={{ textDecoration: 'none', color: 'inherit' }}></RouterLink>
      </ListItemIcon>
    }
    sx={{ paddingLeft: indentation }}
  >
    <ButtonBase component={RouterLink} to={`/handout/${contentId}`} state={{breadcrumbs: breadcrumbs}} style={{ width: '100%', textAlign: 'left', display: 'block' }}>
      <ListItemText primary={<Typography variant="body1">{contentName}</Typography>} />
    </ButtonBase>
  </ListItem>
);

export default ContentListItem;
