import UserApi from '../rest-api/user-api';
import axios from 'axios';
import JSZip from 'jszip';

export default class DownloadUtils {

  static getMimeType(url) {
    const extension = (url.split('.').pop() || '').toLowerCase();
    switch (extension) {
      case 'jpg':
      case 'jpeg':
        return 'image/jpeg';
      case 'png':
        return 'image/png';
      case 'gif':
        return 'image/gif';
      case 'webp':
        return 'image/webp';
      // Add more formats as needed
      default:
        return 'application/octet-stream'; // Default to binary stream. Adjust if necessary.
    }
  }

  static async downloadImageFromPresignedUrl(presignedUrl) {
    try {
      const mimeType = this.getMimeType(presignedUrl);
      console.log(`DownloadUtils downloadImageFromPresignedUrl mimeType: ${mimeType}, presignedUrl: ${presignedUrl}`);

      // Make a GET request to the presigned URL and capture the response
      const response = await axios.get(presignedUrl, { responseType: 'blob' });

      // Log the ETag header from the response
      const eTag = response.headers['etag'];
      console.log(`Downloaded image ETag: ${eTag}`);

      const blob = new Blob([response.data], { type: mimeType });

      return new Promise((resolve) => {
        // Convert blob to base64
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          const base64data = reader.result;
          resolve({ base64data, eTag });  // Return the image data along with ETag
        };
      });
    } catch (error) {
      // Log the error
      console.error(`Error downloading image from presigned URL: ${error}`);
      // You can also rethrow the error or handle it differently if needed
      throw error;
    }
  }


  static async downloadAndExtractContentZip(bearerToken) {
    try {
      // Get the presigned URL for downloading the ZIP
      console.log('downloadAndExtractContentZip');
      const result = await UserApi.generatedContentPresignedUrl(bearerToken);
      console.log(`result: ${JSON.stringify(result)}`);
      const presignedUrls = result.getUrls;
      console.log(`presignedUrls: ${JSON.stringify(presignedUrls)}`);
      const presignedUrl = presignedUrls[0].presignedUrl;
      console.log(`presignedUrl: ${presignedUrl}`);

      // Download the ZIP file content
      const response = await axios.get(presignedUrl, { responseType: 'arraybuffer' });

      // Create a JSZip instance and load the ZIP content
      console.log('response data length:', response.data.length);
      const jszip = new JSZip();
      await jszip.loadAsync(response.data);

      // Extract content.json from the ZIP and parse it
      console.log('about to extract content.json');
      const contentJsonData = await jszip.file("content.json").async("string");

      console.log('about to parse content.json');
      const content = JSON.parse(contentJsonData);

      // Return the parsed content
      return content;

    } catch (error) {
      console.error("Error in downloadAndExtractContentZip:", error);
      throw error;
    }
  };

  // Function to convert base64 to Uint8Array
  static base64ToUint8Array(base64Data) {
    const byteCharacters = atob(base64Data);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    return new Uint8Array(byteNumbers);
  }

  // Your main function
  static async downloadAndExtractUpdatedContent(bearerToken, lastUpdated) {
    try {
      // Get the base64-encoded ZIP content
      console.log('downloadAndExtractUpdatedContent');
      const base64Data = await UserApi.getUpdatedContent(bearerToken, lastUpdated);

      // Convert base64 to Uint8Array using the function
      const byteArray = this.base64ToUint8Array(base64Data);

      // Create a JSZip instance and load the ZIP content
      console.log('response data length:', byteArray.length);
      const jszip = new JSZip();
      await jszip.loadAsync(byteArray);

      // Extract content.json from the ZIP and parse it
      console.log('about to extract content.json');
      const contentJsonData = await jszip.file("content.json").async("string");

      console.log('about to parse content.json');
      const content = JSON.parse(contentJsonData);

      // Return the parsed content
      return content;
    } catch (error) {
      console.error("Error in downloadAndExtractUpdatedContent:", error);
      throw error;
    }
  };


}
