import React, { useState, useEffect, useContext } from 'react';
import { Skeleton, Box, useMediaQuery, useTheme } from '@mui/material';
import ImageCacheContext from './image-cache-context';
import ImageIcon from '@mui/icons-material/Image';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import DownloadUtils from '../../../utils/download-utils';
import UserApi from '../../../rest-api/user-api';

function HandoutImageLoader({ src, alt, handoutData, ...props }) {
  const [loadedSrc, setLoadedSrc] = useState(null);
  const recentlyCachedUrls = useContext(ImageCacheContext);
  const { imageStore, authStore } = useContext(StoreContext);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm')); 

  const desktopImageStyle = {
    maxWidth: '100%', 
    height: 'auto', 
  };

  useEffect(() => {
    async function fetchAndStoreImage() {
      const locale = 'en-UK';
      const idToken = authStore.idToken;
      const localeData = handoutData.contentData[locale] || [];
      const introductionSection = localeData.find(section => section.sectionName === "introduction") || { blocks: [] };
      const firstIllustration = introductionSection.blocks.find(block => block.type === 'illustration');
      
      if (firstIllustration) {
        const imageSrc = firstIllustration.src;
        const imageObject = await imageStore.getImageFromIndexedDB(imageSrc);
        console.warn(imageSrc, imageObject);
        if (!imageObject) {
          try {
            const payload = {
              files: [{ method: "get", key: imageSrc }],
              contentCategory: "image"
            };
            // Get presigned URL for the image
            const presignedUrl = await UserApi.generatedPresignedUrls(idToken, payload);
            console.warn('new image to download..', presignedUrl);
            // Download the image using the presigned URL
            const downloadResult = await DownloadUtils.downloadImageFromPresignedUrl(presignedUrl.getUrls[0]['presignedUrl']);
            // If the download was successful, save the downloaded image to IndexedDB
            console.warn(downloadResult);
            if (downloadResult) {
              const base64data = downloadResult.base64data;
              const etag = downloadResult.etag;
              await imageStore.saveImageToIndexedDB(imageSrc, base64data, etag);
            } else {
              console.error(`Failed to download image: ${downloadResult.reason}`);
            }
          } catch (error) {
            console.error(`Error fetching image: ${error}`);
          }
        }
      }
    }
    
    async function loadImage() {
      console.log(`HandoutImageLoader loadImage: ${src}`);

      // Retrieve the image data and the eTag from IndexedDB
      const imageObject = await imageStore.getImageFromIndexedDB(src);

      // Check if the image data was found, if not, fetch and store it
      if (imageObject && imageObject.data) {
        console.log(`HandoutImageLoader loadImage: ${src} found in IndexedDB`);
        setLoadedSrc(imageObject.data); // Set the loaded image source to the base64 data
      } else {
        console.error(`HandoutImageLoader loadImage: ${src} NOT found in IndexedDB`);
        if (handoutData) fetchAndStoreImage(handoutData);
      }
    }

    loadImage();
  }, [src, imageStore, recentlyCachedUrls, handoutData, authStore]);

  if (!loadedSrc) {
    return (
      <Box position="relative" display="inline-block" width={isMobile ? "100%" : 210} height={118}>
        <Skeleton variant="rectangular" width="100%" height="100%" />
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{ transform: "translate(-50%, -50%)" }}
        >
          <ImageIcon fontSize="large" color="disabled" />
        </Box>
      </Box>
    );
  }

  return (
    <img
      src={loadedSrc}
      alt={alt}
      style={isMobile ? { width: '100%', height: 'auto' } : desktopImageStyle}
      {...props}
    />
  );
}

export default HandoutImageLoader;
