import React, { useState } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, TextField, DialogActions } from '@mui/material';
import ApiButton from '../../UIControls/api-button/api-button';

// NB: onConfirm should be an async function
const ActionDialog = ({
  open,
  title,
  placeholder,
  onCancel,
  onConfirm,
  validate,
  confirmButtonLabel,
  errorTitle,
  errorMessage,
  successTitle,
  successMessage}) => {
  const [inputText, setInputText] = useState('');
  const [isConfirmDisabled, setIsConfirmDisabled] = useState(true);

  const handleChange = (e) => {
    setInputText(e.target.value);
    // Update the disabled state based on the validate function
    setIsConfirmDisabled(validate ? !validate(e.target.value) : false);
  };

  const handleConfirm = async () => {
    if (validate && !validate(inputText)) {
      alert(`${placeholder} cannot be empty or invalid input`);
      return;
    }

    await onConfirm(inputText); // Await the onConfirm call    
  };

  const resetAndCancel = () => {
    console.log('resetAndCancel');
    setInputText('');
    setIsConfirmDisabled(true);
    onCancel();
  }

  return (
    <Dialog open={open} onClose={onCancel}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label={placeholder}
          fullWidth
          value={inputText}
          onChange={handleChange} // Use handleChange to update input and check validation
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={resetAndCancel}>Cancel</Button>
        <ApiButton
          apiCall={handleConfirm}
          // onSuccess={() => {}}          
          buttonLabel={confirmButtonLabel}
          disabled={isConfirmDisabled} // Disable the button based on validation
          errorTitle={errorTitle}
          errorMessage={errorMessage}
          successTitle={successTitle}
          successMessage={successMessage}
          onErrorConfirm={resetAndCancel}
          onSuccessConfirm={resetAndCancel}
        />
      </DialogActions>
    </Dialog>
  );
};

export default ActionDialog;
