import React from 'react';
import { Container, Typography, Box, LinearProgress, IconButton, useTheme, Drawer, List, ListItem, ListItemText } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import arrowWhiteRight from '../../../assets/icons/Arrow-white_Right.svg';
import cetraWhiteLogo from '../../../assets/logos/cetra_white.png';
import { Image } from '@mui/icons-material';

const calculateProgress = (currentStep, totalSteps) => (currentStep / totalSteps) * 100;

export function OnboardingLayout({ 
  title, 
  inputChildren, 
  navChildren, 
  currentStep, 
  totalSteps = 5,
  goBack,
  userData,
  previousStepPath
}) {
  const navigate = useNavigate();
  const theme = useTheme(); 

  const handleBack = () => {
    if (goBack) {
      goBack();
    } else {
      navigate(previousStepPath, { state: { userData } });
    }
  };

  const backButton = (
    <IconButton onClick={handleBack} aria-label="Go back">
      <ArrowBackIcon />
    </IconButton>
  );

  const steps = ["Personal Details", "Clinic Details", "Contact Details", "Password", "Verify Email"];

  const sidebar = (
    <Drawer
      variant="permanent"
      sx={{
        width: 300,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 300, boxSizing: 'border-box', background: 'linear-gradient(132deg, rgb(250, 150, 195) -2.07%, rgb(255, 102, 155) 15.82%, rgb(189, 141, 232) 116.64%)' },
        display: { xs: 'none', md: 'block' }
      }}
    >
      <Box sx={{ paddingTop: 2 }}>
        <img src={cetraWhiteLogo} alt='Cetra Logo' style={{marginLeft:'16px'}}/>
        <Typography variant="h6" align="left" marginLeft={2} marginTop={1} marginBottom={5} color={'white'}>{process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 
        'Empowering pet owners through education.' : 'Extending patient education from clinic to home.'}
        </Typography>
        <List>
          {steps.map((text, index) => (
            <ListItem key={text} selected={index + 1 === currentStep}  sx={{
              backgroundColor: (index + 1 === currentStep) ? 'transparent' : 'transparent',
              '&.Mui-selected': {
                bgcolor: 'transparent'
              },
              '&.Mui-selected, &.Mui-selected:hover': {
                opacity: 1,
                fontWeight: 'bold',
                color: 'white'
              },
              '&:not(.Mui-selected)': {
                opacity: 0.5,
                color: 'white'
              }
            }}>
              <ListItemText primary={text} />
            </ListItem>
          ))}
        </List>
      </Box>
    </Drawer>
  );

  return (
    <Box sx={{ display: 'flex' }}>
      {sidebar}
      <Container component="main" sx={{ flexGrow: 1, minHeight: 400, height: '90vh', position: 'relative' }}>
        {previousStepPath && (
          <Box display="flex" alignItems="center" mt={2}>
            {backButton}
          </Box>
        )}
        
        <Box mt={previousStepPath ? 1 : 4} mb={4}>
          <LinearProgress 
            variant="determinate" 
            value={calculateProgress(currentStep, totalSteps)} 
            sx={{ height: '8px' }}
          />
        </Box>
        
        <Box mb={4} sx={{ [theme.breakpoints.down('sm')]: { marginBottom: 8 } }}>
          <Typography variant="h4" textAlign="left">
            {title}
          </Typography>
        </Box>
        
        <Box display="flex" flexDirection="column" justifyContent="center" flexGrow={1} mb={4}>
          {inputChildren}
        </Box>
        
        <Box
          display="flex"
          flexDirection="column"
          gap={2}
          width={300}
          sx={{
            position: 'relative',
            zIndex: 1,
            [theme.breakpoints.down('md')]: {
              position: 'absolute',
              bottom: 0,
              left: 0,
              right: 0,
              padding: theme.spacing(2),
              width: 'auto',
            }
          }}
        >
          {navChildren}
          {currentStep != 2 && (
          <img
            src={arrowWhiteRight}
            alt='Right arrow'
            style={{
              position: 'absolute',
              marginLeft: 30,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)'
            }}
            width={18}
          />
          )}
        </Box>
      </Container>
    </Box>
  );
}
