export default class UploadUtils {
  
  static createFormData(fields, file) {
    const formData = new FormData();
    Object.entries(fields).forEach(([key, value]) => {
      formData.append(key, value);
    });
    formData.append('Content-Type', file.type);
    formData.append('file', file);

    return formData;
  }

  static debugFormData(formData) {
    // Log FormData; FormData cannot be directly stringified
    console.log('formData:', formData);
    for (var [key, value] of formData.entries()) {
      console.log(`form data key: ${key} value: ${value}`);
    }
  }

  static uploadFile = async (fields, file, presignedUrl, presignedHeadUrl) => {
    try {
      // Create FormData using UploadUtils
      const formData = await UploadUtils.createFormData(fields, file);
      this.debugFormData(formData);

      // Perform the upload
      console.log(`presignedUrl: ${presignedUrl}`);
      const response = await fetch(presignedUrl, {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {

        // Fetch ETag
        const eTag = await this.fetchETag(presignedHeadUrl);
        console.log(`ETag: ${eTag}`);

        // Log the response
        console.log('response:', response);

        if (response.ok) {
          return eTag;
        }
      }

      throw new Error(`Failed to upload file`);
    } catch (err) {
      console.error(`Failed to fetch ETag: ${err}`);
      throw err;
    }
  };

  static fetchETag = async (presignedHeadUrl) => {
    try {
      console.log(`fetchETag presignedHeadUrl: ${presignedHeadUrl}`);
      const response = await fetch(presignedHeadUrl, {
        method: 'HEAD',
      });
      console.log('got response');
      // console.log(`fetchETag response: ${JSON.stringify(response)}`);
      
      if (response.ok) {
        console.log(`response is ok`);
        
        for (const [key, value] of response.headers.entries()) {
          console.log(`fetchETag header in response: ${key}: ${value}`);
          if (key.toLowerCase() === 'etag') {
            return value;
          }
        }
      }
      
      throw new Error(`Failed to fetch ETag`);

    } catch (err) {
      console.log(`Failed to fetch ETag: ${err}`);

      throw err;
    }
  };

  static generateCurlCommand = (presignedUrl, fields, filePath) => {
    let curlCommand = `curl -X POST `;

    // Add form fields
    Object.entries(fields).forEach(([key, value]) => {
      curlCommand += `-F "${key}=${value}" `;
    });

    // Add file
    curlCommand += `-F "file=@${filePath};type=image/png" `;

    // Add presigned URL
    curlCommand += `"${presignedUrl}"`;

    return curlCommand;
  };

}
