import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { ListItem, ListItemText, Typography, Collapse, List, ButtonBase } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ContentListItem from '../search/content-list-item';
import SubcategoryListItem from './subcategory-list-item';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';

const CategoryListItem = observer(({ categoryName, contentItems, isExpanded, onToggleExpand }) => {
  const { categoryStore } = useContext(StoreContext);
  const hasChildren = Object.keys(contentItems.subcategories || {}).length > 0;
  const hasContent = contentItems.items && contentItems.items.length > 0;
  const shouldDisplayExpander = hasChildren || hasContent;

  const handleTrackHandout = async (contentName) => {
    console.warn("HandoutOpened: ", contentName)
    const currentUser = await Auth.currentAuthenticatedUser();

    mixpanel.track('HandoutOpened', {
      email: currentUser.attributes.email,
      handoutTitle: contentName
    });
  };

  const handleToggleSubcategoryExpand = (path) => {
    categoryStore.toggleSubcategoryExpand(path); // updated to use path to handle level 2 nested categories
  };
  
  return (
    <>
      <ListItem secondaryAction={false && <ButtonBase onClick={() => onToggleExpand([categoryName])}>{isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}</ButtonBase>} style={{ paddingLeft: '1em' }}>
        <ListItemText primary={<ButtonBase onClick={() => onToggleExpand([categoryName])}><Typography variant="h6">{categoryName}</Typography></ButtonBase>} />
      </ListItem>
      <Collapse in={true} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {hasContent && contentItems.items.map(content => (
            <ContentListItem key={content.contentId} breadcrumbs={['SEARCH', categoryName]} contentName={content.contentName} contentId={content.contentId} indentation="2em" onHandoutSelect={handleTrackHandout} 
            />
          ))}
          {hasChildren && Object.entries(contentItems.subcategories).map(([subcatName, subcatData]) => (
            <SubcategoryListItem key={subcatName} subcategoryName={subcatName} subcategoryData={subcatData} parentPath={[categoryName]} isExpanded={categoryStore.isSubcategoryExpanded([categoryName, subcatName])} onToggleExpand={handleToggleSubcategoryExpand} indentation={"2em"} />
          ))}
        </List>
      </Collapse>
    </>
  );
});

export default CategoryListItem;
