import React, { useState } from 'react';
import { Box, Button, CircularProgress } from "@mui/material";
import ErrorDialog from './ErrorDialog';
import SuccessDialog from './SuccessDialog';

/* Example usage

onErrorConfirm is useful - do some actions when the user clicks OK on the error dialog
override onSuccess to prevent the default success dialog

<ApiButton
          apiCall={handleConfirm}          
          onSuccess={() => {}}          
          buttonLabel="Confirm"
          disabled={isConfirmDisabled} // Disable the button based on validation
          errorTitle="Email Sending Error"          
          onErrorConfirm={resetAndCancel}
        />

The errorMessage in the ErrorDialog is set to the message of the error exception that is thrown by the apiCall function   

The sx prop should be used in favour of the buttonStyle prop, as it is more flexible
Please note the separate props for color and variant

*/

const ApiButton = ({
  apiCall,
  onSuccess,
  onError,
  buttonLabel,
  buttonStyle,
  variant = "contained",
  color = "secondary",
  errorTitle,
  successTitle,
  successMessage,
  disabled = false,
  onErrorConfirm = () => { },
  onSuccessConfirm = () => { },
  fullWidth = false,
  sx = {},
  image,
  imageSize
}) => {
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('An error occurred.');
  const [isLoading, setIsLoading] = useState(false);  // new state variable for loading status

  const defaultErrorHandler = (error) => {
    setErrorMessage(error.message);
    setOpenErrorDialog(true);
  };

  const defaultSuccessHandler = () => {
    setOpenSuccessDialog(true);
  };

  const handleClick = async () => {
    setIsLoading(true);  // set loading status to true
    try {
      console.log("Operation started...");
      const result = await apiCall();
      console.log("Operation completed successfully!");
      if (onSuccess) {
        onSuccess(result);
      } else {
        defaultSuccessHandler();
      }
    } catch (error) {
      console.error("Operation failed:", error);
      if (onError) {
        onError(error);
      } else {
        defaultErrorHandler(error);
      }
    } finally {
      setIsLoading(false);  // set loading status to false
    }
  };

  return (
    <>
      <Box sx={sx}> {/* Use Box component to apply sx styles */}
        <Button
          variant={variant}
          color={color}
          fullWidth={fullWidth}
          style={buttonStyle}
          onClick={handleClick}
          disabled={isLoading || disabled}
        >
          {isLoading ? <CircularProgress size={24} /> : buttonLabel}
          {image && imageSize && <img src={image} width={imageSize} style={{marginLeft: '8px'}}/>}
        </Button>
      </Box>

      <ErrorDialog
        open={openErrorDialog}
        handleClose={() => {
          //setErrorMessage('An error occurred.');
          setOpenErrorDialog(false);
          onErrorConfirm();
        }}
        title={errorTitle || "Error"}
        message={errorMessage}
      />

      <SuccessDialog
        open={openSuccessDialog}
        handleClose={() => {
          setOpenSuccessDialog(false);
          onSuccessConfirm();
        }}
        title={successTitle || "Success"}
        message={successMessage || "Your action was successful."}
      />
    </>
  );
};

export default ApiButton;
