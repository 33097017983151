import React, { useContext, useState } from 'react';
import { Button, TextField, Container, useMediaQuery, Box, Divider, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography, Drawer } from '@mui/material';
import StoreContext from "../../../react-core-lib/components/app/store-context";
import { useNavigate } from 'react-router-dom';
import cetraLogo from '../../../assets/logos/cetra-01-optimized.svg';
import cetraBlueLogo from '../../../assets/logos/cetra_blue.png';
import cetraWhiteLogo from '../../../assets/logos/cetra_white.png';
import ApiButton from '../../UIControls/api-button/api-button';
import mixpanel from 'mixpanel-browser'; 
import PinkGradientButton from '../../UIControls/pink-gradient-button';

const OnboardLoginScreen = () => {
  const appStateStore = useContext(StoreContext);
  const { authStore } = useContext(StoreContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');  
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  
  const onSubmit = async () => {
    const timestamp = Date.now();
    const dateObject = new Date(timestamp);
    const formattedDate = `${dateObject.getDate()}-${dateObject.getMonth() + 1}-${dateObject.getFullYear()} ${dateObject.getHours()}:${dateObject.getMinutes()}:${dateObject.getSeconds()}`;

    try {
      const result = await authStore.authenticateUser(email, password, (userId) => appStateStore.initializeUserStores(userId));

      if (result.success) {
        // Identify the user with Mixpanel
        mixpanel.identify(email); 

        mixpanel.track('Login Success', {
          email: email,
          time: formattedDate
        });
      } else {
        mixpanel.track('Login Failure', {
          email: email, 
          errorMessage: result.message,
          time: formattedDate
        });

        setIsDialogOpen(true); 

        throw new Error(result.message);
      }
    } catch (error) {
      console.error('Error during authentication:', error);

      // track the error with Mixpanel as well
      mixpanel.track('Login Error', {
        email: email,
        error: error.message,
        time: formattedDate
      });
      if (error.message === 'User is not confirmed.') {
        setErrorMessage('User does not exist - please register')
      } else {
        setErrorMessage(error.message);
      }
      setIsDialogOpen(true);
    }
  };

  const sidebar = (
    <Drawer
      variant="permanent"
      sx={{
        width: 300,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 300, boxSizing: 'border-box', background: 'linear-gradient(132deg, rgb(250, 150, 195) -2.07%, rgb(255, 102, 155) 15.82%, rgb(189, 141, 232) 116.64%)' },
        display: { xs: 'none', md: 'block' }
      }}
    >
      <Box sx={{ paddingTop: 2 }}>
        <img src={cetraWhiteLogo} alt='Cetra Logo' style={{marginLeft:'16px'}}/>
        <Typography variant="h6" align="left" marginLeft={2} marginTop={1} marginBottom={5} color={'white'}>{process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 
        'Empowering pet owners through education.' : 'Extending patient education from clinic to home.'}</Typography>
      </Box>
    </Drawer>
  );

  return (
  <Box sx={{ display: 'flex' }}>
    {sidebar}
    <Container component={'section'} sx={{ minHeight: 500, height: '90vh', position: 'relative' }} >
      <Box sx={{
        display: 'flex',
        justifyContent: 'center', 
        alignItems: 'center', 
        mb: 8, 
        height: 42
      }}>
        {isMobile && (
        <img
          src={cetraBlueLogo}
          alt="Cetra Logo"
          style={{
            maxWidth: '100%', 
            objectFit: 'contain', 
            paddingTop: '16px'
          }}
        />
      )}
      </Box>

      <TextField
        label="Email"
        type="email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        margin="normal"
      />
      <TextField
        label="Password"
        type="password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        fullWidth
        margin="normal"
      />

      <Box sx={{ textAlign: 'left', mt: 2 }}>
          <Button
            disableRipple
            color="primary"
            onClick={()=>navigate('/onboard/login/reset-password')}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent' 
              }
            }}
          >
            Forgot Password?
          </Button>
        </Box>

      <Box width={400} margin={'24px 0'} sx={{
      ...(isMobile && {
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        padding: 2,
        width: 'auto',
        zIndex: 1 
      })
    }}>
        <ApiButton
          apiCall={onSubmit}
          color="primary"
          buttonLabel="Login"
          errorTitle="Login Error"        
          onSuccess={() => {
            console.log('onSuccess called');
          }}
          errorMessage="Unable to login, please try again"
          successMessage="Your clinic image was uploaded successfully!"
          fullWidth={true}
        />

        <Box sx={{ display: 'flex', alignItems: 'center', marginY: 2 }}>
          <Divider sx={{ flex: 1, borderColor: '#000453', mr: 2 }} />
          <Typography sx={{ px: 1, color: '#000453', fontWeight: 600 }}>OR</Typography>
          <Divider sx={{ flex: 1, borderColor: '#000453', ml: 2 }} />
        </Box>

        <Box>
          <PinkGradientButton variant="contained" color="primary" onClick={() => navigate('/onboard/name')} sx={{width: '100%'}}>
            Register Now
          </PinkGradientButton>
        </Box>
      </Box>
      
      <Dialog
        open={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Login Error"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {errorMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsDialogOpen(false)} color="primary" autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  </Box>
  );
};

export default OnboardLoginScreen;
