import React, { useContext } from 'react';
import { useNavigate} from 'react-router-dom';
import { Box, List, Typography,  useMediaQuery, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import ContentListItem from './content-list-item';
import { Auth } from 'aws-amplify';
import mixpanel from 'mixpanel-browser';
import { Feedback } from './search-feedback';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import { SearchCategories } from './search-categories';
import { CategoryButton } from '../../UIControls/category-button';

export const SearchResults = observer(({ breadcrumbs, results, filterSelected = false, activeFilter, homepage, categories, popup = false }) => {
  const { searchStore } = useContext(StoreContext);
  const navigate = useNavigate();
  const theme = useTheme();

  const isTabletOrBelow = useMediaQuery(theme.breakpoints.down('md'));


  const handleTrackHandout = async (contentName) => {
    try {
      const currentUser = await Auth.currentAuthenticatedUser();
      mixpanel.track('HandoutOpened', {
        email: currentUser.attributes.email,
        handoutTitle: contentName
      });
    } catch (error) {
      console.error("Failed to track handout opening:", error);
    }
  };

  const handleButtonClick = (filterType) => {
    if (filterType === 'Condition') {
      navigate('/search', { state: { filter: 'Condition' } });
    } else if (filterType === 'Body area' || filterType === 'Animal') {
      navigate('/search/categories', { state: { filter: filterType } });
    }
  };

  const handleCategoryChange = (category) => {
    console.warn(category);
    navigate('/search', { state: { category: category } });
  };

  const resultsStyle = {
    backgroundColor: 'white',
    paddingLeft: '14px',
    zIndex: 9999,
    minHeight: 200,
    maxHeight: '240px',
    overflowY: 'scroll',
    margin: 0,
    borderRadiusBottomLeft: 12,
    borderRadiusBottomRight: 12,
    width: homepage && !isTabletOrBelow ? '50%' : '100%',
    top: 0
  };

  const filteredResults = filterSelected ? 
    results.filter(result => result.contentName.toLowerCase().includes(activeFilter.toLowerCase())) : 
    results;

  return (
    <Box sx={{ position: 'absolute', width: '100%', top: 48, zIndex: 100,     boxShadow: '0px 8px 8px -4px rgba(0, 0, 0, 0.08), 0px 20px 24px -4px rgba(0, 0, 0, 0.14)'
  }}>
      <List sx={resultsStyle}>
        {filteredResults.map((result, index) => (
          <ContentListItem 
            key={index} 
            contentName={result.contentName} 
            contentId={result.contentId} 
            onHandoutSelect={handleTrackHandout}
            breadcrumbs={breadcrumbs}
          />
        ))}
        {!homepage && !popup && <Feedback style={{ marginTop: '60px' }}/>}
      </List>

      {homepage && !isTabletOrBelow &&
      <Box sx={{ position: 'absolute', right: 0, top: 0, width: '51%', backgroundColor: 'white', zIndex: 1000, height: 240, paddingLeft: 2, paddingTop: 2}} >
        <Typography paddingBottom={2}>
          Browse by
        </Typography>
        <CategoryButton category={'Condition'} onClick={() => handleButtonClick('Condition')} halfWidth={true}/>
        <CategoryButton category={process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 'Animal' : 'Body area'} onClick={() => handleButtonClick(process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 'Animal' : 'Body area')} halfWidth={true}/>
      </Box>}

      {homepage && !isTabletOrBelow && (
        <Box sx={{ position: 'sticky', bottom: 0, width: '100%', backgroundColor: 'white', zIndex: 10, borderBottomRightRadius: 12, borderBottomLeftRadius: 12 }} paddingLeft={2} paddingBottom={3} paddingTop={3}>
          <Typography >
            Categories
          </Typography>
          {categories && Object.keys(categories).length > 0 && <SearchCategories categories={categories} onCategoryChange={handleCategoryChange}/>}
        </Box>
      )}
    </Box>
  );
});
