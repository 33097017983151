import React, { useContext, useState, useEffect, useRef } from 'react';
import { Box, Button, IconButton, InputBase, InputAdornment, Typography, useTheme, useMediaQuery } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { CenteredBox } from '../../UIControls/centered-box';
import { Auth } from 'aws-amplify';
import StoreContext from '../../../react-core-lib/components/app/store-context';
import mixpanel from 'mixpanel-browser';
import { SearchResults } from '../search/search-results';

export const SearchHome = observer(() => {
  const theme = useTheme();
  const { contentStore, searchStore } = useContext(StoreContext);
  const navigate = useNavigate();
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchStore.rawSearchTerm);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const inputRef = useRef(null);
  const [categories, setCategories] = useState({});

  useEffect(() => {
    // Fetch categories once the store is initialized
    if (contentStore.isInitialized) {
      const filteredData = Object.fromEntries(
        Object.entries(contentStore.getCategoriesData()).filter(([key, _]) => key !== 'Body Area' && key !== 'Animal')
      );
      setCategories(filteredData);
    }
  }, [contentStore]);

  useEffect(() => {
    const timerId = setTimeout(() => {
      setDebouncedSearchTerm(searchStore.rawSearchTerm);
      if (searchStore.rawSearchTerm.trim()) {
        searchStore.setSearchTerm(searchStore.rawSearchTerm.trim());
        const results = contentStore.searchContentMeta(searchStore.rawSearchTerm.trim());
        searchStore.setSearchResults(results);
        setDropdownVisible(true);
      } else {
        searchStore.setSearchTerm('');
        searchStore.setSearchResults([]);
        setDropdownVisible(false); 
      }
    }, 200);

    return () => clearTimeout(timerId);
  }, [searchStore.rawSearchTerm, contentStore]);

  const handleTrackSearchAndBlur = async () => {
    console.warn('Home page searched for: ', debouncedSearchTerm);
    if (debouncedSearchTerm.length >= 3) {
      const currentUser = await Auth.currentAuthenticatedUser();
      mixpanel.track('Search', {
        email: currentUser.attributes.email,
        query: debouncedSearchTerm
      });
    }
    setTimeout(() => {
      if (!inputRef.current?.contains(document.activeElement)) {
        setDropdownVisible(false);
      }
    }, 200);
  };

  const handleFocus = () => {
    if (searchStore.rawSearchTerm.trim() && searchStore.searchResults.length > 0) {
      setDropdownVisible(true);
    }
  };

  const handleSearchInputChange = (value) => {
    console.log('SearchHome::onSearchChange', value);
    searchStore.setRawSearchTerm(value);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSearch();
    }
  };

  const handleSearch = () => {
    navigate('/search');
  };

  return (
    <Box
      p={2}
      display="flex"
      flexDirection="column"
      alignItems="center"
      paddingTop={isMobile ? '120px' : '72px'}
      paddingBottom={isMobile ? '32px' : '40px'}
      gap={2}
      sx={{
        width: '100%',
        borderRadius: '4px',
        background: 'linear-gradient(132deg, #FA96C3 -2.07%, #FF669B 15.82%, #BD8DE8 116.64%)',
      }}
      className="search-banner"
    >
      <CenteredBox>
        <Typography variant="h3" textAlign="center" color="white">
          What are you looking for?
        </Typography>
        <Typography variant="h6" textAlign="center" color="white" mb={'-16px'}>
          Search by {process.env.REACT_APP_CETRA_CLINIC_TYPE === 'vet' ? 'condition or animal' : 'medical condition or body part'}  
        </Typography>

        <Box display="flex" alignItems="center" width="100%" position={'relative'}
        sx={{
          backgroundColor: searchStore.rawSearchTerm ? 'white' : 'transparent',
          borderRadius: searchStore.rawSearchTerm && dropdownVisible? '8px 8px 0 0' : '8px',
          transition: 'backgroundColor 0.2s',
        }} className='search-container'>
          <InputBase
            fullWidth
            sx={{
              p: 1,
              borderRadius: 8,
              border: 'none',
              color: searchStore.rawSearchTerm ? 'black' : 'white',
              paddingLeft: '14px',
              background: 'rgba(255,255,255,0.1)',
              '&::placeholder': { color: 'white' },
              '& .MuiInputBase-input': { color: searchStore.rawSearchTerm ? 'black' : 'white' }
            }}
            placeholder="Search..."
            value={searchStore.rawSearchTerm}
            onChange={(e) => handleSearchInputChange(e.target.value)}
            onKeyPress={handleKeyPress}
            onBlur={handleTrackSearchAndBlur}
            onFocus={handleFocus}
            ref={inputRef}
            endAdornment={
              <InputAdornment position="end">
                <IconButton onClick={handleSearch}>
                  <SearchIcon sx={{ color: searchStore.rawSearchTerm ? 'black' : 'white' }} />
                </IconButton>
              </InputAdornment>
            }
          />
          {dropdownVisible && <SearchResults breadcrumbs={['SEARCH']} results={searchStore.searchResults} popup={true} homepage={true} categories={categories} />} 
        </Box>
      </CenteredBox>
    </Box>
  );
});
