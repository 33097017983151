import { Outlet } from 'react-router-dom';
import { HandoutDataProvider } from './handout-data-context';

const HandoutLayout = () => {
  return (
    <HandoutDataProvider>
      <Outlet />
    </HandoutDataProvider>
  );
};

export default HandoutLayout;